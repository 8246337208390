import React, { useEffect, useRef, useState } from "react";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBContainer, MDBInputGroup, MDBRow } from "mdb-react-ui-kit";
import Loader from "../components/Loader";
import { MotionAnimate } from "react-motion-animate";
import { Typewriter } from "react-simple-typewriter";
import Breadcrumb from "../components/common/BreadCrumb";
import { breadcrumbItems } from "../constants/breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { resetCsvStates, uploadCsv } from "../redux/slices/uploadCsvSlice";
import { ToastContainer, toast } from "react-toastify";
import { Download } from "@mui/icons-material";
import csvFile from "./../assets/load_temp .csv";
const maxSizeInMB = 10;
const ImportFiles = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state?.csv.isLoading);
  const uploadCsvStatus = useSelector((state) => state?.csv.uploadCsv);
  const error = useSelector((state) => state?.csv.error);

  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState("");

  const handleFileChange = (e) => {
    setFile(null);
    setFile(e.target.files[0]);
    setFileError("");
  };

  const handleUploadCsv = () => {
    if (!file) {
      setFileError("CSV file is required.");
      return;
    }

    // Check if the uploaded file is a .csv file
    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (fileExtension !== "csv") {
      setFileError("Only CSV files are allowed.");
      return;
    }

    if (file.size > maxSizeInMB * 1024 * 1024) {
      setFileError(`File size should not exceed ${maxSizeInMB}MB.`);
      return;
    }

    const formData = new FormData();
    formData.append("uploaded_file[]", file);
    dispatch(uploadCsv(formData));
  };

  useEffect(() => {
    if (uploadCsvStatus?.status === "Success") {
      toast.success(uploadCsvStatus?.message || "Uploaded successfully");
      setFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
      dispatch(resetCsvStates());
    }
    if (error?.status === "Error") {
      toast.error(error?.message || "Something went wrong");
      setFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
      dispatch(resetCsvStates());
    }
  }, [uploadCsvStatus, error]);

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = csvFile;
    link.download = "Sample-Questions-CSV-file.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="main-container w-100">
      <Loader isLoading={isLoading} />

      <Breadcrumb items={breadcrumbItems.uploadFile} />
      <MDBContainer className="d-flex justify-content-center align-items-center row me-auto ms-auto">
        <MotionAnimate animation="fadeInUp" reset={false} distance={50} delay={0.4} speed={0.5}>
          <MDBRow className="w-100 col-10">
            <MDBCol className="d-flex justify-content-center w-100">
              <MDBCard className="col-10 p-3">
                <MDBCardBody>
                  <MDBCardTitle className="mb-3 title-container">
                    <div className="left">Upload Questions File</div>
                    <div className="right">
                      <MDBBtn className="btn btn-secondary" onClick={handleDownload}>
                        Download Sample File <Download />
                      </MDBBtn>
                    </div>
                  </MDBCardTitle>
                  <p className="text-muted">This interface allows users to upload a CSV file containing questions. Ensure that the file follows the correct format to prevent any issues during the upload process.</p>
                  <ul className="text-muted">
                    <li>Format: The file should be in CSV format with the appropriate headers and data structure.</li>
                    <li>Content: Verify that the questions are correctly formatted and there are no missing values.</li>
                    <li>File Size: Ensure the file size does not exceed the {maxSizeInMB}MB for optimal performance.</li>
                  </ul>
                  <div className="row">
                    <div className="mb-4 col-12">
                      <label className="select-participant-label">Select Questions File (.CSV):</label>
                      <MDBInputGroup textTag="div">
                        <input className="form-control" accept=".csv" type="file" onChange={handleFileChange} ref={fileInputRef} />
                      </MDBInputGroup>
                      {fileError && <small className="text-danger">{fileError}</small>}
                    </div>
                  </div>
                  <MDBBtn className="mt-3" color="dark" onClick={handleUploadCsv}>
                    Upload
                  </MDBBtn>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MotionAnimate>
      </MDBContainer>
      <ToastContainer />
    </div>
  );
};

export default ImportFiles;
