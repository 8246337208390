import React from "react";
import { useNavigate } from "react-router-dom";
import { MDBBtn } from "mdb-react-ui-kit";


const Error = () => {
  const navigate = useNavigate();


  return (
    <div className="not-found-container">
      <div className="error-content">
        <h1>Oops!</h1>
        <h2>404 - Page Not Found</h2>
        <p>We're sorry, but it seems like the page you are looking for cannot be found.</p>
        <p>It's possible that the page has been moved or doesn't exist anymore. Please check the URL or use the navigation buttons below.</p>
        <div className="button-group">
          <MDBBtn onClick={() => navigate(-3)} color="secondary me-5" className="mr-2">
            Go Back
          </MDBBtn>
          <MDBBtn onClick={() => navigate("/")} color="primary ">
            Go to Home
          </MDBBtn>
        </div>
      </div>
    </div>
  );
};

export default Error;
