import { Favorite } from "@mui/icons-material";
import CreateMeeting from "../pages/CreateMeeting";
import ForgotPassword from "../pages/auth/ForgotPassword";
import Login from "../pages/auth/Login";
import SignUp from "../pages/auth/SignUp";
import SavedMeetings from "../pages/SavedMeetings";
import MyFavoriteQuestions from "../pages/MyFavoriteQuestions";
import ImportFiles from "../pages/ImportFiles";
import Dashboard from "../pages/Dashboard";
import ResetPassword from "../pages/auth/UpdatePassword";
import EditMeeting from "../pages/EditMeeting";
import CleanDataBase from "../pages/CleanDataBase";

export const routes = {
  dashboard: "/dashboard",
  error: "/not-found",

  auth: {
    login: {
      path: "/login",
      component: Login,
    },
    signUp: {
      path: "/sign-up",
      component: SignUp,
    },
    forgotPassword: {
      path: "/forgot-password",
      component: ForgotPassword,
    },
    resetPassword: {
      path: "/reset-password",
      component: ResetPassword,
    },
  },
  panel: {
    adminPanel: {
      Dashboard: {
        path: "/dashboard",
        component: Dashboard,
      },
      createMeeting: {
        path: "/create-meeting",
        component: CreateMeeting,
      },
      savedMeetings: {
        path: "/saved-meetings",
        component: SavedMeetings,
      },
      favoriteQuestons: {
        path: "/favorite-questions",
        component: MyFavoriteQuestions,
      },
      addFiles: {
        path: "/add-questions-file",
        component: ImportFiles,
      },
      cleanDataBase: {
        path: "/clean-database",
        component: CleanDataBase,
      }
    },
    userPanel: {
      Dashboard: {
        path: "/dashboard",
        component: Dashboard,
      },
      createMeeting: {
        path: "/create-meeting",
        component: CreateMeeting,
      },

      editMeeting: {
        path: "/edit-meeting",
        component: EditMeeting,
      },

      savedMeetings: {
        path: "/saved-meetings",
        component: SavedMeetings,
      },
      favoriteQuestons: {
        path: "/favorite-questions",
        component: MyFavoriteQuestions,
      },
    },
  },
};

const publicRoutes = [
  {
    name: "Login",
    path: "/login",
    component: Login,
  },
  {
    path: "/sign-up",
    component: SignUp,
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
  },
  {
    path: "/reset-password",
    component: ResetPassword,
  },
];

const userRoutes = [
  {
    path: "/dashboard",
    component: Dashboard,
  },
  {
    path: "/create-meeting",
    component: CreateMeeting,
  },
  {
    path: "/saved-meetings",
    component: SavedMeetings,
  },

  {
    path: "/favorite-questions",
    component: MyFavoriteQuestions,
  },
  {
    path: "/add-questions-file",
    component: ImportFiles,
  },
];
