import React from "react";
import { DotLoader, PuffLoader } from "react-spinners";

const Loader = ({ isLoading }) => {
  return (
    <div className={`loader-overlay ${isLoading ? "visible" : "hidden"}`} style={{ minHeight: "70vh", width: "100vw" }}>
      <div className="loader-container">
        {/* <DotLoader color="#36D7B7" loading={isLoading} size={150} /> */}
        <PuffLoader color="orange" size={100} />
      </div>
    </div>
  );
};

export default Loader;
