import { API } from "./Interceptor";

export const getAllCategoriesService = () => API.get(`/get_all_categories`);
export const getQuestionsService = (data) => API.post(`/questions_by_categories`, data);
export const createNewMeetingService = (data) => API.post(`/create_meeting`, data);
export const getAllMeetingService = () => API.get(`/all_meetings`);
export const updateMeetingService = (data) => API.post(`/update_meeting`, data);

export const getMeetingDetailService = (data) => API.post(`/meeting_detials`, data);
export const deleteMeetingService = (data) => API.post(`/delete_meeting`, data);

export const uploadCsvService = (data) => API.post(`/upload_csv`, data);
export const markAsFavoriteService = (data) => API.post(`/favorite`, data);
export const getAllFavoriteQuestionsService = (data) => API.post(`/favourite_questions`, data);

//auth
export const loginService = (credentials) => API.post(`/login`, credentials);
export const signupService = (userData) => API.post("/register", userData);
export const forgotPasswordService = (email) => API.post(`/forgot_password`, { email });
export const logoutService = () => API.post(`/logout`);
export const updatePasswordService = (data) => API.post(`/reset_password`, data);


//clean database
export const cleanDatabaseService = (credentials) => API.post(`/clean_database`, credentials);
