import React, { useEffect, useState } from "react";
import MySidebarMenu from "../containers/Sidebar";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const UserLayout = () => {
  const isAuthenticated = useSelector((state) => state?.auth?.isAuthenticated);
  const [isLoggedIn, setIsLoggedIn] = useState(isAuthenticated);

  useEffect(() => {
    setIsLoggedIn(isAuthenticated);
  }, [isAuthenticated]);
  return (
    <>
      {isLoggedIn ? (
        <div className="user-layout-container">
          <div className="user-layout-row">
            <div className="user-layout-sidebar ">
              <MySidebarMenu />
            </div>
            <div className="user-layout-content">
              <Outlet />
            </div>
          </div>
        </div>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
};

export default UserLayout;
