import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cleanDatabaseService } from "../services/services";

export const cleanDatabase = createAsyncThunk("clean/database", async (data, { rejectWithValue }) => {
    try {
        const response = await cleanDatabaseService(data);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});


const cleanDatabaseSlice = createSlice({
    name: "cleanDatabase",
    initialState: {
        data: null,
        loading: false,
        error: null,
    },
    reducers: {
        resetCleanDatabase: (state) => {
            state.data = null;
            state.loading = false;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(cleanDatabase.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(cleanDatabase.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(cleanDatabase.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export const { resetCleanDatabase } = cleanDatabaseSlice.actions;
export default cleanDatabaseSlice.reducer;
