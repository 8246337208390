import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { routes } from "./routes";

const PublicRoutes = () => {
  const isAuthenticated = useSelector((state) => state?.auth?.isAuthenticated);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    setIsLoggedIn(isAuthenticated);
  }, [isAuthenticated]);

  return !isLoggedIn ? <Outlet /> : <Navigate to={routes?.dashboard} />;
};

export default PublicRoutes;
