import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { loginService, signupService, forgotPasswordService, logoutService, updatePasswordService } from "../services/services";
import { clearLocalStorage, getLocalStorage, setLocalStorage } from "../../utils/utils";

// Define async thunks for each authentication action
export const login = createAsyncThunk("auth/login", async (credentials, { rejectWithValue }) => {
  try {
    const response = await loginService(credentials);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const signup = createAsyncThunk("auth/signup", async (userData, { rejectWithValue }) => {
  try {
    const response = await signupService(userData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const forgotPassword = createAsyncThunk("auth/forgotPassword", async (email, { rejectWithValue }) => {
  try {
    const response = await forgotPasswordService(email);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const logout = createAsyncThunk("auth/logout", async (_, { rejectWithValue }) => {
  try {
    const response = await logoutService();
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const updatePassword = createAsyncThunk("auth/updatePassword", async (data, { rejectWithValue }) => {
  try {
    const response = await updatePasswordService(data);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Define the initial state for the auth slice
const initialState = {
  isLoading: false,
  error: null,
  user: null,
  isAuthenticated: getLocalStorage("userToken") && getLocalStorage("userType") ? true : false,
  userType: getLocalStorage("userType") || null,
  registerUser: null,
  logoutStatus: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuthStates: (state) => {
      state.isLoading = false;
      state.error = null;
      state.user = null;
      state.registerUser = null;
    },

    resetAllData: (state) => {
      state.isLoading = false;
      state.error = null;
      state.user = null;
      state.isAuthenticated = false;
      state.registerUser = null;
      state.logoutStatus = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
        state.token = action.payload?.data?.token;
        state.userType = action.payload?.data?.user_type;

        // Check if token exists and set it to localStorage
        if (action.payload?.data?.token) {
          setLocalStorage("userToken", action.payload?.data?.token);
        }

        // Check if user_type exists and set it to localStorage
        if (action.payload?.data?.user_type) {
          setLocalStorage("userType", action.payload?.data?.user_type);
        }
        if (action.payload?.data?.token && action.payload?.data?.user_type) {
          state.isAuthenticated = true;
        }
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(signup.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(signup.fulfilled, (state, action) => {
        state.isLoading = false;
        state.registerUser = action.payload;
      })
      .addCase(signup.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(forgotPassword.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      })
      .addCase(logout.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.isLoading = false;
        state.logoutStatus = action?.payload;
        state.isAuthenticated = false;
      })
      .addCase(logout.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(updatePassword.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      });
  },
});

// Export the actions and the reducer
export const { resetAuthStates, resetAllData } = authSlice.actions;
export default authSlice.reducer;
