import React, { useEffect, useState } from "react";
import { MDBBtn, MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardImage, MDBInput, MDBIcon } from "mdb-react-ui-kit";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../config/routes";
import { useDispatch, useSelector } from "react-redux";
import { PuffLoader } from "react-spinners";
import { validateResetPassword } from "../../validator/authValidator";
import { resetAuthStates, updatePassword } from "../../redux/slices/authSlice";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";

function ResetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const email = location?.state?.email;
  const isLoading = useSelector((state) => state.auth.isLoading);
  const user = useSelector((state) => state.auth.user);

  const [errors, setErrors] = useState({});
  const [formValues, setFormValues] = useState({
    token: "",
    password: "",
    repeatPassword: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const ValidationErrors = validateResetPassword(formValues);

    if (ValidationErrors === null) {
      setErrors([]);
      const { token, password, repeatPassword } = formValues;
      const payload = {
        email: email,
        token: token?.trim(),
        password: password,
        password_confirmation: repeatPassword,
      };
      dispatch(updatePassword(payload));
    } else {
      setErrors(ValidationErrors);
    }
  };

  useEffect(() => {
    if (user?.status === "Success") {
      Swal.fire({
        icon: "success",
        title: user?.message,
        showConfirmButton: true,
      }).then(() => {
        navigate(routes.auth.login.path);
      });
    } else if (user?.message) {
      toast.error(user?.message || "Something went wrong");
    }
    dispatch(resetAuthStates());
  }, [user]);

  useEffect(() => {
    if (!email) {
      navigate(routes.auth.forgotPassword.path);
    }
  }, [email]);

  return (
    <MDBContainer fluid className="d-flex align-items-center justify-content-center" style={{ minHeight: "100vh", backgroundColor: "#EEEEEE" }}>
      <MDBCard className="text-black m-5 col-6 pt-5 pb-5" style={{ borderRadius: "25px" }}>
        <MDBCardBody>
          <MDBRow>
            <div className="w-100 d-flex justify-content-center">
              <span className="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4 " style={{ fontWeight: "700", fontSize: "2.5rem" }}>
                Reset Your Password?
              </span>
            </div>

            <MDBCol md="10" lg="6" className="order-2 order-lg-1 d-flex flex-column align-items-center">
              <div className="d-flex flex-row align-items-center mt-4" style={{ width: "80%" }}>
                <MDBIcon fas icon="key me-3" size="lg" />
                <MDBInput label="Token" id="token" size="lg" type="password" autoComplete="off" value={formValues?.token} onChange={handleChange} />
              </div>
              <div className="w-100" style={{ marginLeft: "34%", marginTop: "-2px" }}>
                {errors?.token && <small className="text-danger ">{errors?.token}</small>}{" "}
              </div>
              <div className="d-flex flex-row align-items-center mt-4" style={{ width: "80%" }}>
                <MDBIcon fas icon="lock me-3" size="lg" />
                <MDBInput label="Password" id="password" size="lg" type="password" autoComplete="off" value={formValues.password} onChange={handleChange} />
              </div>
              <div className="w-100" style={{ marginLeft: "34%", marginTop: "-2px" }}>
                {errors?.password && <small className="text-danger ">{errors?.password}</small>}{" "}
              </div>

              <div className="d-flex flex-row align-items-center mt-4" style={{ width: "80%" }}>
                <MDBIcon fas icon="lock me-3" size="lg" />
                <MDBInput label="Repeat your password" size="lg" id="repeatPassword" type="password" value={formValues.repeatPassword} onChange={handleChange} />
              </div>
              <div className="w-100" style={{ marginLeft: "34%", marginTop: "-2px" }}>
                {errors?.repeatPassword && <small className="text-danger ">{errors?.repeatPassword}</small>}{" "}
              </div>

              <div className="d-flex flex-row align-items-center mb-4  mb-4" style={{ width: "80%", paddingLeft: "6%" }}>
                <MDBBtn className="mb-4 mt-4 ml-5 w-100" size="lg" color="secondary" onClick={handleSubmit}>
                  <span className="d-flex flex-row align-items-center justify-content-center g-p">{isLoading ? <PuffLoader color="orange" className="me-3" size={25} /> : <span> Update Password</span>}</span>
                </MDBBtn>
              </div>

              <div className="d-flex flex-row justify-content-center mb-4">
                <p className="text-center text-muted mb-0">Remembered your password?</p>
                <p onClick={() => navigate(routes.auth.login.path)} className="ms-2 text-decoration-underline fw-bold text-body" role="button">
                  Login
                </p>
              </div>
            </MDBCol>

            <MDBCol md="10" lg="6" className="order-1 order-lg-2 d-flex align-items-center">
              <MDBCardImage src="https://media.istockphoto.com/id/1372135382/vector/account-access-vector-illustration-in-flat-design.jpg?s=612x612&w=0&k=20&c=z3e6g9tRUYTlOIxROy0QOSjT3HhGrByzhZaxChCVHEA=" fluid />
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
      <ToastContainer />
    </MDBContainer>
  );
}

export default ResetPassword;
