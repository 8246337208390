export const validateLogin = (email, password) => {
  const errors = {};

  // Email validation
  if (!email) {
    errors.email = "Email is required";
  } else if (!/\S+@\S+\.\S+/.test(email)) {
    errors.email = "Email is invalid";
  }

  // Password validation
  if (!password) {
    errors.password = "Password is required";
  } else if (password.length < 6) {
    errors.password = "Password must be at least 6 characters long";
  }

  // If there are no errors, return null
  return Object.keys(errors).length === 0 ? null : errors;
};

export const validateSignUp = (formValues) => {
  const errors = {};

  // Name validation
  if (!formValues?.name) {
    errors.name = "Name is required";
  } else if (formValues?.name.length < 3) {
    errors.name = "Name must be at least 3 characters long";
  }

  // Email validation
  if (!formValues?.email) {
    errors.email = "Email is required";
  } else if (!/\S+@\S+\.\S+/.test(formValues?.email)) {
    errors.email = "Email is invalid";
  }

  // Password validation
  if (!formValues?.password) {
    errors.password = "Password is required";
  } else if (formValues?.password.length < 8) {
    errors.password = "Password must be at least 8 characters long";
  }

  // Repeat password validation
  if (!formValues?.repeatPassword) {
    errors.repeatPassword = "Repeat password is required";
  } else if (formValues?.repeatPassword !== formValues?.password) {
    errors.repeatPassword = "Passwords do not match";
  }

  // If there are no errors, return null
  return Object.keys(errors).length === 0 ? null : errors;
};

export const validateForgot = (email) => {
  const errors = {};

  // Email validation
  if (!email) {
    errors.email = "Email is required";
  } else if (!/\S+@\S+\.\S+/.test(email)) {
    errors.email = "Email is invalid";
  }

  // If there are no errors, return null
  return Object.keys(errors).length === 0 ? null : errors;
};

export const validateResetPassword = (formValues) => {
  const errors = {};
  if (!formValues?.token) {
    errors.token = "Token is required";
  }
  if (!formValues?.password) {
    errors.password = "Password is required";
  } else if (formValues?.password.length < 8) {
    errors.password = "Password must be at least 8 characters long";
  }

  // Repeat password validation
  if (!formValues?.repeatPassword) {
    errors.repeatPassword = "Repeat password is required";
  } else if (formValues?.repeatPassword !== formValues?.password) {
    errors.repeatPassword = "Passwords do not match";
  }

  // If there are no errors, return null
  return Object.keys(errors).length === 0 ? null : errors;
};
