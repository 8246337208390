import React, { useEffect, useState } from "react";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { MDBDataTable } from "mdbreact";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { DeleteForever } from "@mui/icons-material";
import Breadcrumb from "../components/common/BreadCrumb";
import { breadcrumbItems } from "../constants/breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import { createNewMeeting, getAllCategories, getQuestions, resetCreateMeeting, resetQuestions, resetQuestionsStatus } from "../redux/slices/meetingSlice";
import { getAllFavoriteQuestions, markAsFavorite, resetFavoriteStates } from "../redux/slices/favoriteQuestionsSlice";
import { ToastContainer, toast } from "react-toastify";
import { columns } from "./../constants/data";
import Loader from "../components/Loader";
import moment from "moment";
import { searchValidatorFromFavorite, validateParticipantInfo, validateParticipantsfromFavourite } from "../validator/createMeetingValidation";
import AssignQuestionsModal from "../components/AssignQuestionsModal";
import { routes } from "../config/routes";
import { useNavigate } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

const animatedComponents = makeAnimated();

const MyFavoriteQuestions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const questions = useSelector((state) => state.meeting.questions);
  const questionsdata = useSelector((state) => state.meeting.questionsdata);
  const { categories, isLoading } = useSelector((state) => state.meeting);
  const allFavoriteQuestions = useSelector((state) => state.favorite?.allFavoriteQuestions);
  const loading = useSelector((state) => state.favorite?.isLoading);
  const addToFavorite = useSelector((state) => state?.favorite?.addToFavorite);
  const createMeeting = useSelector((state) => state.meeting.createNewMeeting);
  const [participantData, setParticipantData] = useState([]);
  const [assignedQuestions, setAssignedQuestions] = useState([]);

  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isRtl, setIsRtl] = useState(false);

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [validationError, setValidationError] = useState({});
  const [participantsQuestion, setParticipantsQuestions] = useState([]);
  const [submitClick, setSubClick] = useState(false);

  const [tdata, settdata] = useState([]);
  const [userGridData, setUserGridData] = useState({
    columns,
    rows: [],
  });

  const [selectedQuestions, setSelectedQuestions] = useState([]);

  const handleCategoryChange = (selectedOption, actionMeta) => {
    setSelectedCategories((prevSelectedCategories) => ({
      ...prevSelectedCategories,
      [actionMeta.name]: selectedOption,
    }));
  };

  const handleSearch = () => {
    setSubClick(true);
    const category = Object.values(selectedCategories).filter((value) => value != null);
    const categoryArray = category.map(({ keyword_id, category_id }) => ({
      keyword_id,
      category_id,
    }));

    const error = searchValidatorFromFavorite(categoryArray, categories);
    if (error === null) {
      dispatch(getAllFavoriteQuestions({ search: categoryArray }));
      setValidationError({});
    } else {
      setValidationError(error);
    }
  };

  const capitalizeFirstLetter = (str) => {
    return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleCheckboxChange = (event, questionId) => {
    if (event.target.checked) {
      if (participantData?.length >= 3) {
        toast.info("You have reached the maximum limit of 3 participants for the meeting.");
        return;
      }

      if (selectedQuestions.length < 5) {
        setSelectedQuestions([...selectedQuestions, questionId]);
      } else {
        // Notify user that only 15 questions can be selected
        toast.error("You can select a maximum of 5 questions.");
      }
    } else {
      setSelectedQuestions(selectedQuestions.filter((id) => id !== questionId));
    }
  };

  const handleFavorite = (id) => {
    const payload = {
      question_id: id,
      favorite: "N",
    };
    dispatch(markAsFavorite(payload));
  };

  const isQuestionAssigned = (questionId) => {
    return participantsQuestion.some((assignedGroup) => assignedGroup.question_id === questionId);
  };

  const handleRefresh = () => {
    setParticipantsQuestions([]);
    setSelectedQuestions([]);
    setSubClick(false);
    setValidationError({});
  };

  const handleCancel = () => {
    handleRefresh();
    setParticipantData([]);
  };

  useEffect(() => {
    if (submitClick) {
      const category = Object.values(selectedCategories).filter((value) => value != null);
      const categoryArray = category.map(({ keyword_id, category_id }) => ({
        keyword_id,
        category_id,
      }));

      const errors = searchValidatorFromFavorite(categoryArray, categories);
      setValidationError(errors ?? {});
    }
  }, [selectedCategories]);

  useEffect(() => {
    setUserGridData((prevData) => ({
      ...prevData,
      rows: tdata.map((item, i) => ({
        ...item,
        Sr: i + 1,
        categories: item?.categories?.map((category) => category).join(", "),
        action: (
          <div className="icon-container">
            <MDBBtn color="light" disabled={isQuestionAssigned(item.question_id)} onClick={() => handleFavorite(item?.question_id)}>
              <DeleteForever className=" delete-icon" />
            </MDBBtn>
          </div>
        ),
        select: <input type="checkbox" checked={selectedQuestions.includes(item)} disabled={isQuestionAssigned(item.question_id)} onChange={(e) => handleCheckboxChange(e, item)} />,
      })),
    }));
  }, [tdata, selectedQuestions, participantsQuestion]);

  useEffect(() => {
    if (createMeeting?.status === "Success") {
      toast.success("Meeting created successfully");
      handleRefresh();
    } else if (createMeeting?.status === "Error") {
      toast.error(createMeeting?.message || "Somthing went wrong");
    }
    dispatch(resetCreateMeeting());
  }, [createMeeting]);

  useEffect(() => {
    if (allFavoriteQuestions?.status === "Success") {
      if (allFavoriteQuestions?.data?.length > 0) {
        settdata(allFavoriteQuestions?.data ?? []);
      } else {
        settdata([]);
        toast.info("No results found in your favourite list for the selected category and keywords");
      }
    } else if (allFavoriteQuestions?.status === "Error") {
      if (allFavoriteQuestions?.message === "validation_error" && Array.isArray(allFavoriteQuestions?.data)) {
        const errorMessages = allFavoriteQuestions?.data.join("<br>"); // Join the array of error messages with <br>
        toast.error(<div dangerouslySetInnerHTML={{ __html: errorMessages }} />);
      } else {
        toast.error(allFavoriteQuestions?.message);
      }
    }
    dispatch(resetFavoriteStates());
  }, [allFavoriteQuestions]);

  useEffect(() => {
    if (addToFavorite?.status === "Success") {
      toast.success(addToFavorite?.message);
      handleSearch();
    }
    dispatch(resetFavoriteStates());
  }, [addToFavorite]);

  useEffect(() => {
    dispatch(getAllCategories());
  }, []);

  const isButtonDisabled = Object.values(selectedCategories).filter((value) => value != null).length === 0;

  const handleOpenModal = () => {
    if (selectedQuestions?.length < 5) {
      const payload = Object?.values(selectedCategories).map((option) => ({
        keyword_id: option.keyword_id,
        category_id: option.category_id,
      }));
      const limit = 5 - selectedQuestions?.length;
      const formData = new FormData();
      formData.append("search", JSON.stringify(payload));
      formData.append("limit", limit);
      selectedQuestions.forEach((question, index) => {
        formData.append(`skipped_questions[${index}]`, question?.question_id);
      });
      dispatch(getQuestions(formData));
    } else {
      setIsModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleParticipantDataAdd = (data) => {
    setParticipantData([...participantData, data]);
    setAssignedQuestions([...assignedQuestions, ...selectedQuestions]);
    setParticipantsQuestions([...participantsQuestion, ...data.questions]);
    setSelectedQuestions([]);
    setIsModalOpen(false);
  };

  const handleCreateNewMeeting = () => {
    const category = Object.values(selectedCategories).filter((value) => value != null);
    navigate(routes.panel.userPanel.createMeeting.path, { state: { participantData: participantData, searchCriteria: category } });
    // setParticipantData([]);
  };

  useEffect(() => {
    if (questionsdata?.status === "Success") {
      if (selectedQuestions?.length > 0) {
        setSelectedQuestions([...selectedQuestions, ...questions]);
        setIsModalOpen(true);
      }
    }
    dispatch(resetQuestionsStatus());
    dispatch(resetQuestions());
  }, [questionsdata]);

  const renderPopover = () => (
    <Popover id="participants-popover">
      <Popover.Header as="h3">Participants Name</Popover.Header>
      <Popover.Body>
        {participantData.map((participant, index) => (
          <div className="mb-1" key={index}>
            {index + 1}. {participant.name}
          </div>
        ))}
      </Popover.Body>
    </Popover>
  );
  return (
    <>
      <div className="main-container w-100">
        {/* <div className="sticky-top"> */}
        <Breadcrumb items={breadcrumbItems.myFavoriteQuestions} />
        {/* </div> */}
        <Loader isLoading={loading || isLoading} />
        <MDBContainer className="d-flex justify-content-center align-items-center row ms-auto me-auto">
          {categories?.length > 0 && (
            <MDBRow className="w-100  col-12 ">
              <MDBCol>
                <MDBCard className="w-100 p-3">
                  <MDBCardBody>
                    <MDBCardTitle className="mb-3">Filter Questions By:</MDBCardTitle>

                    <div className=" row mb-4 g-3">
                      {categories?.map((category, index) => {
                        const options = category.keywords.map((keyword) => ({
                          value: keyword.keyword_value,
                          label: keyword.keyword_value,
                          keyword_id: keyword?.keyword_id,
                          category_id: category?.category_id,
                        }));
                        return (
                          <div className="col-4 " key={index}>
                            <label className="select-participant-label">
                              {capitalizeFirstLetter("Select")} {capitalizeFirstLetter(category?.category_name)}
                              {category?.is_mandatory === "Y" ? "*" : ""}:
                            </label>
                            <Select
                              className="basic-single"
                              components={animatedComponents}
                              classNamePrefix="select"
                              isDisabled={isDisabled}
                              isLoading={isLoading}
                              isClearable={isClearable}
                              isRtl={isRtl}
                              isSearchable={isSearchable}
                              name={category?.category_name}
                              onChange={handleCategoryChange}
                              options={options}
                            />
                            {validationError?.[category?.category_id] && <small className="text-danger">{validationError?.[category?.category_id]}</small>}
                          </div>
                        );
                      })}
                    </div>
                    <MDBBtn className="mt-3 " disabled={isButtonDisabled} color="dark" onClick={handleSearch}>
                      Search
                    </MDBBtn>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          )}

          {tdata?.length > 0 && (
            <MDBRow className="w-100 col-12 mt-5 mb-5">
              <MDBCol className="d-flex justify-content-center w-100">
                <MDBCard className="w-100 p-3">
                  <MDBCardBody>
                    {/* <Typewriter words={[""]} /> */}
                    <MDBCardTitle className="mb-3 title-container">
                      <div className="left">My Favorite Questions</div>
                      <div className="right">
                        {participantData?.length > 0 && (
                          <>
                            <MDBBtn className="btn btn-secondary" color="danger" onClick={handleCancel}>
                              X
                            </MDBBtn>

                            <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={renderPopover()}>
                              <MDBBtn className="btn btn-secondary" color="black" onClick={handleCreateNewMeeting}>
                                Create New Meeting - {participantData?.length}
                              </MDBBtn>
                            </OverlayTrigger>
                          </>
                        )}
                      </div>
                    </MDBCardTitle>

                    {tdata?.length > 0}
                    <MDBDataTable responsive noBottomColumns disableRetreatAfterSorting={true} entriesLabel="Show Questions" infoLabel={["Showing", "to", "of", "Questions"]} striped small data={userGridData} />

                    <MDBRow className="w-100 col-12 m-0 p-0">
                      <MDBCol className="d-flex w-100 m-0 p-0">
                        {selectedQuestions?.length > 0 && (
                          <>
                            <MDBBtn className="mt-5  me-4 btn btn-secondary" onClick={() => setSelectedQuestions([])}>
                              Remove
                            </MDBBtn>

                            <MDBBtn className="mt-5  " color="dark" onClick={() => handleOpenModal()}>
                              Assign Questions to participant
                            </MDBBtn>
                          </>
                        )}
                      </MDBCol>
                    </MDBRow>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          )}
        </MDBContainer>
        {isModalOpen && <AssignQuestionsModal open={isModalOpen} onClose={handleCloseModal} questions={questions?.length > 0 ? [selectedQuestions, ...questions] : selectedQuestions} handleParticipantData={handleParticipantDataAdd} />}
        <ToastContainer />
      </div>
    </>
  );
};

export default MyFavoriteQuestions;
