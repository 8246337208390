import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;

// "http://192.168.0.137:8080/api";

export const API = axios.create({
  baseURL: baseUrl,
});

// Function to handle logout
const logout = () => {
  localStorage.removeItem("userToken");
  localStorage.removeItem("userType");
  window.location.href = "/login";
};

// Add a request interceptor
API.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("userToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.data && error.response.data.message === "Unauthenticated.") {
      logout();
    }
    return Promise.reject(error);
  }
);
