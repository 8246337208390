import jsPDF from "jspdf";
import "jspdf-autotable"; // for table layout

// utils.js
export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};

export const getLocalStorage = (key) => {
  return localStorage.getItem(key);
};

export const clearLocalStorage = (key) => {
  localStorage.removeItem(key);
};

export const logout = () => {};

export const formatDateTime = (dateTimeString) => {
  // Replace ' ' with 'T' and handle AM/PM manually
  const [datePart, timePart, ampmPart] = dateTimeString.split(" ");
  const [hours, minutes, seconds] = timePart.split(":");
  let hours24 = parseInt(hours, 10);

  if (ampmPart === "PM" && hours24 !== 12) {
    hours24 += 12;
  } else if (ampmPart === "AM" && hours24 === 12) {
    hours24 = 0;
  }

  const isoString = `${datePart}T${hours24.toString().padStart(2, "0")}:${minutes}:${seconds}`;
  const date = new Date(isoString);

  if (isNaN(date.getTime())) {
    return { formattedDate: "Invalid Date", formattedTime: "Invalid Time" };
  }

  // Define month names
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  // Extract month, day, and year
  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  // Format the date as "MMM-DD-YYYY"
  const formattedDate = `${day} ${month} ${year}`;

  // Extract and format time
  let hours12 = date.getHours() % 12 || 12; // Convert to 12-hour format
  const formattedMinutes = date.getMinutes().toString().padStart(2, "0");
  const formattedSeconds = date.getSeconds().toString().padStart(2, "0");
  const formattedAmpm = date.getHours() >= 12 ? "PM" : "AM";
  const formattedTime = `${hours12}:${formattedMinutes}:${formattedSeconds} ${formattedAmpm}`;

  return { formattedDate, formattedTime };
};

const handleGeneratePdf = (data, participants) => {
  const doc = new jsPDF();

  // Define page dimensions and margin
  const pageHeight = doc.internal.pageSize.height;
  const pageWidth = doc.internal.pageSize.width;
  const margin = 10;

  // Function to add footer
  const addFooter = (doc) => {
    const currentYear = new Date().getFullYear();
    const footerText = `© The Sales Chief ${currentYear}`;

    // Calculate text width and center position
    const textWidth = (doc.getStringUnitWidth(footerText) * doc.internal.getFontSize()) / doc.internal.scaleFactor;
    const centerPos = (pageWidth - textWidth) / 2;

    // Set font size and position
    doc.setFontSize(10);

    doc.text(footerText, centerPos, pageHeight - margin);
  };

  // Meeting details table
  const tableRows = [];

  data.forEach((row) => {
    const rowData = [row.heading, row.content];
    tableRows.push(rowData);
  });

  doc.autoTable({
    body: tableRows,
    theme: "grid",
    startY: margin,
    styles: {
      overflow: "linebreak",
    },
    columnStyles: {
      0: { cellWidth: 91 },
      1: { cellWidth: 91 },
    },
    willDrawCell: (data) => {
      // Apply background color to specific rows
      const rowsToHighlight = [0];
      if (rowsToHighlight.includes(data.row.index)) {
        doc.setFillColor(225, 225, 225);
        doc.setFontSize(14);
      }
    },
    didParseCell: (data) => {
      if (data.row.index === 0) {
        data.cell.styles.fontStyle = "bold";
      }
    },
    didDrawPage: (data) => {
      addFooter(doc);
    },
  });

  // Adding participants tables
  participants.forEach((participant, index) => {
    const participantRows = [
      [`Participant ${index + 1}`, participant.participant_name],
      ["Job Title", participant.participant_job_title],
      ["Question", "Answer"],
    ];

    participant.questions.forEach((question, i) => {
      participantRows.push([`${i + 1}. ${question.question_text}`, ""]);
    });

    // Ensure there's space for the new table, or add a new page
    let currentY = doc.previousAutoTable.finalY + margin;
    if (currentY + participantRows.length * 10 > pageHeight - margin) {
      doc.addPage();
      currentY = margin;
    }

    doc.autoTable({
      body: participantRows,
      theme: "grid",
      startY: currentY,
      styles: {
        overflow: "linebreak",
        color: true,
      },
      columnStyles: {
        0: { cellWidth: 91 },
        1: { cellWidth: 91 },
      },
      willDrawCell: (data) => {
        // Apply background color to specific rows
        const rowsToHighlight = [0, 1, 2];
        if (rowsToHighlight.includes(data.row.index)) {
          data.cell.styles.fillColor = "#FF0000";
          doc.setFillColor(225, 225, 225);
        }
      },
      didParseCell: (data) => {
        // if (data.row.index === 0) {
        //   data.cell.styles.fontStyle = "bold";
        // }
      },
      didDrawPage: (data) => {
        addFooter(doc);
      },
    });
  });

  doc.save(`${data[1]?.content}-${data[2]?.content}.pdf`);
};

export default handleGeneratePdf;
