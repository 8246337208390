import React, { useEffect } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Dashboard, Add, List, StarOutline, FileUpload, Delete } from "@mui/icons-material";
import { MDBBtn, MDBCardImage } from "mdb-react-ui-kit";
import { useNavigate, useLocation } from "react-router-dom";
import { routes } from "../config/routes";
import "./../styles/sideBar.css";
import logo from "./../assets/logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { logout, resetAllData } from "../redux/slices/authSlice";
import { PuffLoader } from "react-spinners";

const SidebarItem = ({ icon, text, path, activePath }) => {
  const navigate = useNavigate();
  const isActive = path === activePath;

  return (
    <MenuItem icon={icon} className="mb-2" onClick={() => navigate(path)} style={{ backgroundColor: isActive ? "#404751" : "transparent", color: isActive ? "#f5fcfe" : "#000" }}>
      {text}
    </MenuItem>
  );
};

const MySidebarMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname;

  const { isLoading, logoutStatus, error, userType } = useSelector((state) => state.auth);

  const userLogout = () => {
    dispatch(logout());
  };

  useEffect(() => {
    if (logoutStatus?.status === "Success" || error?.message === "Unauthenticated.") {
      // window.location.reload();
      dispatch(resetAllData());
      // navigate(routes.auth.login.path);
      localStorage.removeItem("userToken");
      localStorage.removeItem("userType");
      window.location.href = "/login";
    }
  }, [logoutStatus, error]);

  return (
    <Sidebar style={{ height: "100vh", display: "flex", flexDirection: "column", alignItems: "stretch" }}>
      <Menu className="mt-3">
        <div className="d-flex align-items-center mb-3 w-100 ps-4">
          <MDBCardImage src={logo} style={{ height: "30px", width: "auto", marginTop: "-5px" }} />
          <span className="ms-2">The Sales Chief</span>
        </div>
        <SidebarItem icon={<Dashboard />} text="Dashboard" path={routes.panel.userPanel.Dashboard.path} activePath={path} />
        <div className="border border-secondary mb-3"></div>
        {userType?.toLowerCase() === "admin" && <SidebarItem icon={<FileUpload />} text="Upload Questions File" path={routes.panel.adminPanel.addFiles.path} activePath={path} />}
        <SidebarItem icon={<Add />} text="Create New Meeting" path={routes.panel.userPanel.createMeeting.path} activePath={path} />
        <SidebarItem icon={<List />} text="Saved Meetings" path={routes.panel.userPanel.savedMeetings.path} activePath={path} />
        <SidebarItem icon={<StarOutline />} text="My Favorite Questions" path={routes.panel.adminPanel.favoriteQuestons.path} activePath={path} />
        {userType?.toLowerCase() === "admin" && <SidebarItem icon={<Delete />} text="Clean Database" path={routes.panel.adminPanel.cleanDataBase.path} activePath={path} />}



      </Menu>
      <MDBBtn className="me-1 mb-3 m-2" color="danger" onClick={userLogout}>
        <span className="d-flex flex-row align-items-center justify-content-center g-p">{isLoading ? <PuffLoader color="orange" className="me-3" size={25} /> : <span>Logout</span>}</span>
      </MDBBtn>
    </Sidebar>
  );
};

export default MySidebarMenu;
