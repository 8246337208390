import moment from "moment";

export const newMeetingDataValidator = (payload) => {
  const errors = {};

  // Check if meeting date time is present and valid
  if (!payload.meeting_date_time || !moment(payload.meeting_date_time, "YYYY-MM-DD HH:mm:ss", true).isValid()) {
    errors.meeting_date_time = "Meeting date time is required or invalid.";
  }
  if (!payload.company_name) {
    errors.company_name = "Company name is required.";
  }

  // Check if meeting name is present
  if (!payload.meeting_name) {
    errors.meeting_name = "Meeting name is required.";
  }

  // Check if participants array is present and not empty
  if (!payload.participants || payload.participants.length === 0) {
    errors.participants = "At least one participant is required.";
  }

  // Validate each participant
  // payload?.participants?.forEach((participant, index) => {
  //   if (!participant.question_ids || participant.question_ids.length === 0) {
  //     errors[index] = "At least one question is required for the participant.";
  //   }
  // });
  // }

  // Return errors object if there are any, otherwise return null
  return Object.keys(errors).length === 0 ? null : errors;
};

export const searchValidator = (userCategories, categories, index) => {
  const errors = [];

  // Validate job title (unchanged)
  if (!userCategories?.jobTitle || userCategories?.jobTitle === "") {
    errors.push({ index, category_id: "jobTitle", message: "Job Title is required" });
  }

  // Filter mandatory categories (correct logic)
  const mandatoryCategories = categories?.filter((category) => category.is_mandatory === "Y");
  const mandatoryCategoryIds = mandatoryCategories.map((category) => category.category_id);

  // Get filtered categories (unchanged)
  const filteredCategories = Object.values(userCategories)?.flatMap((cat) => (cat?.category_id ? [cat.category_id] : []));

  // Validate mandatory category presence (corrected logic)
  mandatoryCategoryIds?.forEach((mandatoryId) => {
    if (!filteredCategories.includes(mandatoryId)) {
      // Include the index and category_id in the error object for identification
      errors.push({ index, category_id: mandatoryId, message: "This category is mandatory." });
    }
  });

  return errors.length === 0 ? null : errors;
};

export const validateParticipantsfromFavourite = (payload) => {
  const errors = {};

  // Check if meeting date time is present and valid
  if (!payload.meeting_date_time || !moment(payload.meeting_date_time, "YYYY-MM-DD HH:mm:ss", true).isValid()) {
    errors.meeting_date_time = "Meeting date time is required or invalid.";
  }
  if (!payload.company_name) {
    errors.company_name = "Company name is required.";
  }

  // Check if meeting name is present
  if (!payload.meeting_name) {
    errors.meeting_name = "Meeting name is required.";
  }

  // Check if participants array is present and not empty
  if (!payload.participants || payload.participants.length === 0) {
    errors.participants = "At least one participant is required.";
  }

  // Validate each participant
  payload?.participants?.forEach((participant, index) => {
    if (!participant.participants_name) {
      errors[`participants_name-${index}`] = "Participant name is required.";
    }
    if (!participant.job_title) {
      errors[`job_title-${index}`] = "Participant job title is required.";
    }
  });
  // }

  return Object.keys(errors).length === 0 ? null : errors;
};

export const validateParticipantInfo = (participantInfo, meeting) => {
  const errors = {};

  if (!meeting.date || !moment(meeting.date, "YYYY-MM-DD HH:mm:ss", true).isValid()) {
    errors.meeting_date_time = "Meeting date time is required or invalid.";
  }
  if (!meeting.companyName) {
    errors.company_name = "Company name is required.";
  }

  // Check if meeting name is present
  if (!meeting.name) {
    errors.meeting_name = "Meeting name is required.";
  }
  // Validate each participant
  participantInfo?.forEach((participant, index) => {
    if (!participant?.name) {
      errors[`participants_name-${index}`] = "Participant name is required.";
    }
    if (!participant?.jobTitle) {
      errors[`job_title-${index}`] = "Participant job title is required.";
    }
  });
  // }

  return Object.keys(errors).length === 0 ? null : errors;
};

export const editSearchValidator = (userCategories, categories, index) => {
  const errors = [];

  // Validate job title (unchanged)
  if (!userCategories[`jobTitle-${index}`] || userCategories[`jobTitle-${index}`] === "") {
    errors.push({ index, category_id: `jobTitle-${index}`, message: "Job Title is required" });
  }

  // Filter mandatory categories (correct logic)
  const mandatoryCategories = categories?.filter((category) => category.is_mandatory === "Y");
  const mandatoryCategoryIds = mandatoryCategories.map((category) => category.category_id);

  // Get filtered categories (unchanged)
  const filteredCategories = Object.values(userCategories)?.flatMap((cat) => (cat?.category_id ? [cat.category_id] : []));

  // Validate mandatory category presence (corrected logic)
  mandatoryCategoryIds?.forEach((mandatoryId) => {
    if (!filteredCategories.includes(mandatoryId)) {
      // Include the index and category_id in the error object for identification
      errors.push({ index, category_id: mandatoryId, message: "This category is mandatory." });
    }
  });

  return errors.length === 0 ? null : errors;
};

export const searchValidatorFromFavorite = (userCategories, categories) => {
  const errors = {};

  // Filter mandatory categories
  const mandatoryCategories = categories?.filter((category) => category.is_mandatory === "Y");
  const mandatoryCategoryIds = mandatoryCategories.map((category) => category.category_id);

  // Get filtered categories
  const filteredCategories = userCategories.map((cat) => cat.category_id);

  // Validate mandatory category presence
  mandatoryCategoryIds?.forEach((mandatoryId) => {
    if (!filteredCategories.includes(mandatoryId)) {
      errors[mandatoryId] = "This category is mandatory.";
    }
  });

  return Object.keys(errors).length === 0 ? null : errors;
};
