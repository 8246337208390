export const breadcrumbItems = {
  dashboard: [
    { name: "Home", path: "/", icon: "Home" },
    { name: "Dashboard", path: "/dashboard", icon: "Dashboard" },
  ],
  createNewMeetings: [
    // { name: "Home", path: "/", icon: "Home" },
    { name: "Create New Meeting", path: "/create-meeting", icon: "Add" },
  ],

  savedMeetings: [
    // { name: "Home", path: "/", icon: "Home" },
    { name: "Saved Meetings", path: "/saved-meetings", icon: "List" },
  ],

  editMeetings: [
    { name: "Saved Meetings", path: "/saved-meetings", icon: "List" },
    { name: "Edit Meeting", path: "/edit-meeting", icon: "Edit" },
  ],
  myFavoriteQuestions: [
    // { name: "Home", path: "/", icon: "Home" },
    { name: "My Favorite Questions", path: "/favorite-questions", icon: "Favorite" },
  ],
  uploadFile: [{ name: "Upload Questions File", path: "/add-questions-file", icon: "FileUpload" }],
  cleanDatabase: [{ name: "Clean Database", path: "/clean-database", icon: "Delete" }],

};
