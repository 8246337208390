export const colourOptions = [
  { value: "revenue_growth", label: "Revenue Growth" },
  { value: "customer_acquisition", label: "Customer Acquisition" },
  { value: "market_penetration", label: "Market Penetration" },
  { value: "product_performance", label: "Product Performance" },
  { value: "sales_pipeline", label: "Sales Pipeline" },
  { value: "competitive_analysis", label: "Competitive Analysis" },
  { value: "customer_retention", label: "Customer Retention" },
];

export const participantOptions = [
  { value: "Stephen Samith", label: "Stephen Samith" },
  { value: "Ali Kamdar", label: "Ali Kamdar" },
  { value: "Emily Chen", label: "Emily Chen" },
  { value: "Michael Johnson", label: "Michael Johnson" },
  { value: "Maria Rodriguez", label: "Maria Rodriguez" },
  { value: "David Smith", label: "David Smith" },
  { value: "Sarah Patel", label: "Sarah Patel" },
  { value: "Kevin Nguyen", label: "Kevin Nguyen" },
  { value: "Jennifer Lee", label: "Jennifer Lee" },
  { value: "Mohammed Khan", label: "Mohammed Khan" },
];

export const flavourOptions = [
  { value: "vanilla", label: "Vanilla", rating: "safe" },
  { value: "chocolate", label: "Chocolate", rating: "good" },
  { value: "strawberry", label: "Strawberry", rating: "wild" },
  { value: "salted-caramel", label: "Salted Caramel", rating: "crazy" },
];

export const stateOptions = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AS", label: "American Samoa" },
  // Rest of the states...
];

export const optionLength = [
  { value: 1, label: "general" },
  {
    value: 2,
    label: "Evil is the moment when I lack the strength to be true to the Good that compels me.",
  },
  {
    value: 3,
    label: "It is now an easy matter to spell out the ethic of a truth: 'Do all that you can to persevere in that which exceeds your perseverance. Persevere in the interruption. Seize in your being that which has seized and broken you.",
  },
];

export const dogOptions = [
  { id: 1, label: "Chihuahua" },
  { id: 2, label: "Bulldog" },
  { id: 3, label: "Dachshund" },
  { id: 4, label: "Akita" },
];

export const groupedOptions = [
  {
    label: "Colours",
    options: colourOptions,
  },
  {
    label: "Flavours",
    options: flavourOptions,
  },
];

export const questions = [
  {
    name: "Stephen Smith",
    question: ["What is the capital of France?", "Describe the process of photosynthesis.", "Explain the Pythagorean theorem.", "Who painted the Mona Lisa?", "What is the largest ocean on Earth?"],
  },
  {
    name: "Ali Kamdar",
    question: ["What are the causes of the French Revolution?", "How do plants use glucose for energy?", "How is the Pythagorean theorem applied in real life?", "When was the Mona Lisa painted?", "What are the different layers of the Earth's atmosphere?"],
  },
  {
    name: "John Doe",
    question: ["In which year did the French Revolution begin?", "What are the different parts of a plant cell?", "What is the proof of the Pythagorean theorem?", "What is the technique used to paint the Mona Lisa called?", "What are the greenhouse gases and their impact?"],
  },
];

export const salesStages = [
  { value: "prospecting", label: "Prospecting" },
  { value: "qualifying", label: "Qualifying" },
  { value: "demonstration", label: "Demonstration" },
  { value: "proposal", label: "Proposal" },
  { value: "negotiation", label: "Negotiation" },
  { value: "closed_won", label: "Closed - Won" },
  { value: "closed_lost", label: "Closed - Lost" },
  { value: "follow_up", label: "Follow-up" },
  { value: "contract_sent", label: "Contract Sent" },
  { value: "contract_review", label: "Contract Review" },
];

export const objectives = [
  { value: "increase_revenue", label: "Increase Revenue" },
  { value: "expand_market_share", label: "Expand Market Share" },
  { value: "improve_customer_retention", label: "Improve Customer Retention" },
  { value: "streamline_operations", label: "Streamline Operations" },
  { value: "enhance_brand_visibility", label: "Enhance Brand Visibility" },
  { value: "optimize_profitability", label: "Optimize Profitability" },
  { value: "strengthen_customer_relationships", label: "Strengthen Customer Relationships" },
  { value: "develop_new_products", label: "Develop New Products" },
  { value: "enter_new_markets", label: "Enter New Markets" },
  { value: "improve_employee_engagement", label: "Improve Employee Engagement" },
];

export const participantTitles = [
  { value: "Team Leader", label: "Team Leader" },
  { value: "Manager", label: "Manager" },
  { value: "Supervisor", label: "Supervisor" },
  { value: "Coordinator", label: "Coordinator" },
  { value: "Executive", label: "Executive" },
  { value: "Specialist", label: "Specialist" },
  { value: "Analyst", label: "Analyst" },
  { value: "Consultant", label: "Consultant" },
  { value: "Officer", label: "Officer" },
  { value: "Associate", label: "Associate" },
];

export const participantRoles = [
  { value: "Influencer", label: "Influencer" },
  { value: "Technical Buyer", label: "Technical Buyer" },
  { value: "Decision Maker", label: "Decision Maker" },
  { value: "End User", label: "End User" },
  { value: "Gatekeeper", label: "Gatekeeper" },
  { value: "Champion", label: "Champion" },
  { value: "Evaluator", label: "Evaluator" },
  { value: "Budget Holder", label: "Budget Holder" },
  { value: "Project Manager", label: "Project Manager" },
  { value: "Stakeholder", label: "Stakeholder" },
];

export const relationshipStatuses = [
  { value: "Not Met", label: "Not Met" },
  { value: "Acquaintance", label: "Acquaintance" },
  { value: "Colleague", label: "Colleague" },
  { value: "Friend", label: "Friend" },
  { value: "Client", label: "Client" },
  { value: "Prospect", label: "Prospect" },
  { value: "Lead", label: "Lead" },
  { value: "Customer", label: "Customer" },
  { value: "Partner", label: "Partner" },
  { value: "Vendor", label: "Vendor" },
];

export const toneOptions = [
  { value: "Informal", label: "Informal" },
  { value: "Formal", label: "Formal" },
  { value: "Friendly", label: "Friendly" },
  { value: "Professional", label: "Professional" },
  { value: "Casual", label: "Casual" },
  { value: "Polite", label: "Polite" },
  { value: "Neutral", label: "Neutral" },
  { value: "Authoritative", label: "Authoritative" },
  { value: "Warm", label: "Warm" },
  { value: "Respectful", label: "Respectful" },
];

export const ratingOptions = [
  { value: "1+", label: "1+" },
  { value: "2+", label: "2+" },
  { value: "3+", label: "3+" },
  { value: "4+", label: "4+" },
  { value: "5+", label: "5+" },
];

export const columns = [
  {
    label: "#",
    field: "Sr",
    sort: "asc",
    width: 150,
  },
  {
    label: "Select",
    field: "select",
    width: 100,
  },
  {
    label: "Questions",
    field: "question_text",
    sort: "asc",
    width: 150,
  },
  {
    label: "Filtered By",
    field: "categories",
    sort: "asc",
    width: 100,
  },
  {
    label: "Actions",
    field: "action",
    width: 100,
    sort: "asc",
  },
];

export const columnsForSavedMeetingTable = [
  {
    label: "#",
    field: "Sr",
    sort: "asc",
    width: 150,
  },
  {
    label: "Created Date",
    field: "created_date",
    sort: "asc",
    width: 150,
  },
  {
    label: "Scheduled Date",
    field: "start_date",
    sort: "asc",
    width: 150,
  },

  {
    label: "Scheduled Time",
    field: "start_time",
    sort: "asc",
    width: 150,
  },

  {
    label: "Meeting Name",
    field: "meeting_name",
    sort: "asc",
    width: 150,
  },
  {
    label: "Company Name",
    field: "company_name",
    sort: "asc",
    width: 100,
  },
  {
    label: "Participant 1",
    field: "Participant1",
    sort: "asc",
    width: 100,
  },
  {
    label: "Participant 2",
    field: "Participant2",
    sort: "asc",
    width: 100,
  },
  {
    label: "Participant 3",
    field: "Participant3",
    width: 100,
    sort: "asc",
  },
  {
    label: "Actions",
    field: "action",
    width: 100,
    sort: "asc",
  },
];
