import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import UserLayout from "./layout/UserLayout";
import { routes } from "./config/routes";
import CreateMeeting from "./pages/CreateMeeting";
import Error from "./pages/Error";
import "./styles/common.css";
import "react-toastify/dist/ReactToastify.css";
import PublicRoutes from "./config/PublicRoutes";
import { useSelector } from "react-redux";


function App() {
  const { userType } = useSelector((state) => state.auth);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to={routes.auth.login.path} />} />
          <Route element={<PublicRoutes />}>
            <Route path={routes.auth.login.path} element={<routes.auth.login.component />} />
            <Route path={routes.auth.signUp.path} element={<routes.auth.signUp.component />} />
            <Route path={routes.auth.forgotPassword.path} element={<routes.auth.forgotPassword.component />} />
            <Route path={routes.auth.resetPassword.path} element={<routes.auth.resetPassword.component />} />
          </Route>
          <Route element={<UserLayout />}>
            <Route exact path={routes.panel.adminPanel.Dashboard.path} element={<routes.panel.adminPanel.Dashboard.component />} />
            <Route path={routes.panel.userPanel.createMeeting.path} element={<CreateMeeting />} />
            <Route path={routes.panel.userPanel.editMeeting.path} element={<routes.panel.userPanel.editMeeting.component />} />

            <Route path={routes.panel.userPanel.savedMeetings.path} element={<routes.panel.userPanel.savedMeetings.component />} />
            <Route path={routes.panel.userPanel.favoriteQuestons.path} element={<routes.panel.userPanel.favoriteQuestons.component />} />


            {userType?.toLowerCase() === "admin" && <Route path={routes.panel.adminPanel.addFiles.path} element={<routes.panel.adminPanel.addFiles.component />} />}
            {userType?.toLowerCase() === "admin" && <Route path={routes.panel.adminPanel.cleanDataBase.path} element={<routes.panel.adminPanel.cleanDataBase.component />} />}
          </Route>

          <Route path="*" element={<Navigate to={routes.error} />} />
          <Route path={routes.error} element={<Error />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
