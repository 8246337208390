import React, { useEffect, useState, useRef } from "react";
import { MotionAnimate } from "react-motion-animate";
import Loader from "../components/Loader";
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { MDBDataTable } from "mdbreact";
import { Edit, Download, DeleteForever } from "@mui/icons-material";
import Breadcrumb from "../components/common/BreadCrumb";
import { breadcrumbItems } from "../constants/breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { deleteMeeting, getAllMeetings, getMeetingDetails, resetMeetingDelete, resetMeetingDetail } from "../redux/slices/meetingSlice";
import handleGeneratePdf, { formatDateTime } from "../utils/utils";
import jsPDF from "jspdf";
import { ToastContainer, toast } from "react-toastify";
import { routes } from "../config/routes";
import { useNavigate } from "react-router-dom";
import { columnsForSavedMeetingTable } from "../constants/data";
import ReportTemplate from "./ReportTemplate";
import MeetingDetailsPdfContent from "../components/MeetingDetailsPdfContent";
import { renderToString } from "react-dom/server";

const SavedMeetings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const savedMeetings = useSelector((state) => state.meeting.allSavedMeetings);
  const isLoading = useSelector((state) => state.meeting.isLoading);
  const meetingDetails = useSelector((state) => state.meeting.meetingDetails);
  const meetingDelete = useSelector((state) => state.meeting.deleteMeeting);

  const [userGridData, setUserGridData] = useState({
    columns: columnsForSavedMeetingTable,
    rows: [],
  });

  const handleSelectOne = (index) => {
    setUserGridData((prevData) => ({
      ...prevData,
      rows: prevData.rows.map((item, i) => (i === index ? { ...item, select: !item.select } : item)),
    }));
  };

  const handleDelete = (meeting) => {
    dispatch(deleteMeeting({ meeting_id: meeting }));
  };

  useEffect(() => {
    dispatch(getAllMeetings());
  }, [dispatch]);

  const handleDownload = (meeting) => {
    dispatch(getMeetingDetails({ meeting_id: meeting }));
    // handleGeneratePdf();
  };

  useEffect(() => {
    if (savedMeetings) {
      const formattedData = savedMeetings.map((meeting, index) => {
        const participants = meeting.participants || [];
        const { formattedDate, formattedTime } = formatDateTime(meeting?.created_date_time);
        const { formattedDate: startDate, formattedTime: startTime } = formatDateTime(meeting.meeting_start_date_time);

        return {
          Sr: index + 1,
          created_date: `${formattedDate}`,
          start_date: `${startDate}`,
          start_time: startTime,
          meeting_name: meeting.meeting_name,
          company_name: meeting.company_name,
          Participant1: participants[0]?.participant_name || "",
          Participant2: participants[1]?.participant_name || "",
          Participant3: participants[2]?.participant_name || "",
          select: <input type="checkbox" className="form-check-input ms-2" checked={meeting.select || false} onChange={() => handleSelectOne(index)} />,
          action: (
            <div className="icon-container">
              <span onClick={() => handleDelete(meeting?.id)}>
                <DeleteForever className="icon delete-icon" />
              </span>
              <span onClick={() => handleNavigateToEditPage(meeting?.id)}>
                <Edit className="icon visibility-icon" />
              </span>
              <span onClick={() => handleDownload(meeting?.id)}>
                <Download className="icon download-icon" />
              </span>
            </div>
          ),
        };
      });

      setUserGridData((prevData) => ({
        ...prevData,
        rows: formattedData,
      }));
    }
  }, [savedMeetings]);

  const handleNavigateToEditPage = (id) => {
    navigate(routes?.panel.userPanel.editMeeting.path, { state: { id } });
  };
  const reportTemplateRef = useRef(null);

  useEffect(() => {
    if (meetingDetails?.status === "Success") {
      const { meeting_name, company_name, participants, meeting_start_date_time } = meetingDetails.data[0] || {};
      const { formattedDate, formattedTime } = formatDateTime(meeting_start_date_time);
      const meetingData = [
        { heading: "Meeting Details", content: null },
        { heading: "Meeting Name:", content: meeting_name },
        { heading: "Company Name:", content: company_name },
        { heading: "Meeting Date:", content: formattedDate },
        { heading: "Meeting Time:", content: formattedTime },
      ];

      handleGeneratePdf(meetingData, participants);
    }
    dispatch(resetMeetingDetail());
  }, [meetingDetails]);

  //   console.log({ totalParticipants });

  //   const doc = new jsPDF({
  //     format: "a4",
  //     unit: "px",
  //   });

  //   const content = renderToString(<MeetingDetailsPdfContent meeting={meeting} />);

  //   // Add an initial page
  //   let currentY = 10;

  //   const addContentToDoc = (contentElement, heightEstimate) => {
  //     if (currentY + heightEstimate > doc.internal.pageSize.height - 10) {
  //       doc.addPage();
  //       currentY = 10;
  //     }
  //     doc.html(contentElement, {
  //       callback: function () {
  //         currentY += heightEstimate;
  //       },
  //       x: 10,
  //       y: currentY,
  //       autoPaging: "text",
  //       width: 420,
  //       windowWidth: 650,
  //     });
  //   };

  //   // Splitting content manually
  //   const virtualDoc = new jsPDF({
  //     format: "a4",
  //     unit: "px",
  //   });
  //   virtualDoc.html(content, {
  //     callback: function () {
  //       console.log(content);
  //       const tableElements = virtualDoc.internal.pages[1].filters[0].elems;
  //       tableElements.forEach((tableElement, index) => {
  //         const heightEstimate = 100; // Estimate the height for each table
  //         addContentToDoc(tableElement, heightEstimate);
  //       });

  //       // Save the final document
  //       doc.save(`${meeting.meeting_name}.pdf`);
  //     },
  //     x: 10,
  //     y: 5,
  //     width: 420,
  //     windowWidth: 650,
  //   });
  // };

  // const handleGeneratePdf = (meeting) => {
  //   const totalParticipants = meeting?.participants?.length;
  //   console.log({ totalParticipants });

  //   const doc = new jsPDF({
  //     format: "a4",
  //     unit: "px",
  //     // format: totalParticipants > 2 ? [440, 880] : [440, 600],
  //   });

  //   const content = renderToString(<MeetingDetailsPdfContent meeting={meeting} />);

  //   doc.html(content, {
  //     callback: function (doc) {
  //       console.log({ doc });
  //       doc.save(`${meeting.meeting_name}.pdf`);
  //     },
  //     x: 10,
  //     y: 5,

  //     width: 420, // Adjust width as needed
  //     windowWidth: 650, // Adjust window width as needed
  //   });
  // };

  // const handleGeneratePdf = (meeting) => {
  //   const totalParticipants = meeting?.participants?.length;
  //   const format = totalParticipants === 1 ? [440, 600] : totalParticipants === 2 ? [440, 740] : totalParticipants === 3 ? [440, 920] : [440, 1080];
  //   const footerYPosition = totalParticipants === 1 ? 580 : totalParticipants === 2 ? 720 : totalParticipants === 3 ? 900 : 1050;
  //   const doc = new jsPDF({
  //     unit: "px",
  //     format,
  //   });

  //   const content = renderToString(<MeetingDetailsPdfContent meeting={meeting} />);
  //   const currentYear = new Date().getFullYear();
  //   doc.html(content, {
  //     callback: function (doc) {
  //       // Add footer on each page
  //       const pageCount = doc.internal.getNumberOfPages();
  //       for (let i = 1; i <= pageCount; i++) {
  //         doc.setPage(i);
  //         doc.setTextColor(90, 90, 90);
  //         doc.text(`© The Sales Chief ${currentYear}`, 173, footerYPosition); // Adjust the position as needed
  //       }
  //       doc.save(`${meeting.meeting_name}.pdf`);
  //     },
  //     x: 10,
  //     y: 5,
  //     width: 420, // Adjust width as needed
  //     windowWidth: 650, // Adjust window width as needed
  //   });
  // };

  useEffect(() => {
    if (meetingDelete?.status === "Success") {
      toast?.success(meetingDelete?.message);
      dispatch(getAllMeetings());
    } else if (meetingDelete?.status === "Error") {
      toast?.error(meetingDelete?.message);
    }
    dispatch(resetMeetingDelete());
  }, [meetingDelete]);

  return (
    <div className="main-container w-100">
      <Loader isLoading={isLoading} />

      <Breadcrumb items={breadcrumbItems.savedMeetings} />
      <MDBContainer className="d-flex justify-content-center align-items-center row ms-auto me-auto">
        <MotionAnimate animation="fadeInUp" reset={false} distance={50} delay={0.4} speed={0.5}>
          <MDBRow className="w-100 col-12">
            <MDBCol className="d-flex justify-content-center w-100">
              <MDBCard className="w-100 p-3">
                <MDBCardBody>
                  <MDBCardTitle className="mb-3">List Saved Meetings</MDBCardTitle>
                  <MDBDataTable responsive noBottomColumns entriesLabel="Show Meetings" infoLabel={["Showing", "to", "of", "Meetings"]} striped small data={userGridData} />
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MotionAnimate>
      </MDBContainer>

      <ToastContainer />
    </div>
  );
};

export default SavedMeetings;
