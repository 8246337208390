import React from "react";
import PropTypes from "prop-types";
import { MDBBreadcrumb, MDBBreadcrumbItem, MDBContainer, MDBNavbar } from "mdb-react-ui-kit";
import { Home, Add, Save, Favorite, Dashboard, FileUpload, List, StarOutline, Edit, Delete } from "@mui/icons-material";
import { Link, useLocation, useNavigate } from "react-router-dom";


const iconMap = {
  Home: <Home />,
  Add: <Add />,
  Save: <Save />,
  Favorite: <StarOutline />,
  Dashboard: <Dashboard />,
  FileUpload: <FileUpload />,
  List: <List />,
  Edit: <Edit />,
  Delete: <Delete />,
};

const Breadcrumb = ({ items }) => {
  return (
    <MDBNavbar expand="lg" light bgColor="light" style={{ height: "50px" }}>
      <MDBContainer fluid>
        <MDBBreadcrumb>
          {items.map((item, index) => (
            <MDBBreadcrumbItem key={index} active={item.active}>
              {item.icon && iconMap[item.icon] && <span className="me-2">{iconMap[item.icon]}</span>}
              {item.active ? item.name : <Link to={item.path}>{item.name}</Link>}
            </MDBBreadcrumbItem>
          ))}
        </MDBBreadcrumb>
      </MDBContainer>
    </MDBNavbar>
  );
};

Breadcrumb.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      path: PropTypes.string,
      active: PropTypes.bool,
      icon: PropTypes.string,
    })
  ).isRequired,
};

export default Breadcrumb;
