import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createNewMeetingService, getAllCategoriesService, getAllMeetingService, getMeetingDetailService, getQuestionsService, deleteMeetingService, updateMeetingService } from "../services/services";

export const getAllCategories = createAsyncThunk("meeting/getAllCategories", async (_, { rejectWithValue }) => {
  try {
    const response = await getAllCategoriesService();
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const getQuestions = createAsyncThunk("meeting/getQuestions", async (meetingData, { rejectWithValue }) => {
  try {
    const response = await getQuestionsService(meetingData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const createNewMeeting = createAsyncThunk("meeting/createNewMeeting", async (meetingData, { rejectWithValue }) => {
  try {
    const response = await createNewMeetingService(meetingData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const getAllMeetings = createAsyncThunk("meeting/getAllmeetings", async (_, { rejectWithValue }) => {
  try {
    const response = await getAllMeetingService();
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const getMeetingDetails = createAsyncThunk("meeting/getMeetingDetails", async (data, { rejectWithValue }) => {
  try {
    const response = await getMeetingDetailService(data);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const getMeetingDetailsForUpdate = createAsyncThunk("meeting/getMeetingDetailsForUpdate", async (data, { rejectWithValue }) => {
  try {
    const response = await getMeetingDetailService(data);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const deleteMeeting = createAsyncThunk("meeting/deleteMeeting", async (data, { rejectWithValue }) => {
  try {
    const response = await deleteMeetingService(data);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const updateMeetingDetails = createAsyncThunk("meeting/updateMeetingDetails ", async (data, { rejectWithValue }) => {
  try {
    const response = await updateMeetingService(data);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const meetingSlice = createSlice({
  name: "meeting",
  initialState: {
    isLoading: false,
    searchForParticepent: "",
    categories: [],
    categoriesStatus: null,
    error: null,
    questions: [],
    questionsStatus: false,
    questionsdata: null,
    createNewMeeting: null,
    allSavedMeetings: [],
    meetingDetails: null,
    deleteMeeting: null,
    meetingDetailsForUpdate: null,
    updateMeeting: null,
  },
  reducers: {
    setMeetingName: (state, action) => {
      state.name = action.payload;
    },
    setMeetingDate: (state, action) => {
      state.date = action.payload;
    },
    resetCreateMeeting: (state, action) => {
      state.createNewMeeting = null;
      state.questions = [];
      state.updateMeeting = null;
    },
    resetQuestions: (state, action) => {
      state.questions = [];
      state.questionsdata = null;
      state.questionsStatus = false;
    },
    resetQuestionsStatus: (state, action) => {
      state.questionsStatus = false;
    },
    resetMeetingDetail: (state, action) => {
      state.meetingDetails = null;
      state.meetingDetailsForUpdate = null;
    },
    resetMeetingDelete: (state, action) => {
      state.deleteMeeting = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCategories.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAllCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.categories = action.payload.data;
        state.categoriesStatus = action.payload;
      })
      .addCase(getAllCategories.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getQuestions.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getQuestions.fulfilled, (state, action) => {
        state.isLoading = false;
        // Assuming you want to update some state property with the questions
        // Adjust this line according to your state structure
        state.questions = action.payload.data;
        state.questionsdata = action.payload;
        state.questionsStatus = action.payload.data?.length === 0;
      })
      .addCase(getQuestions.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(createNewMeeting.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createNewMeeting.fulfilled, (state, action) => {
        state.isLoading = false;
        state.createNewMeeting = action.payload;
      })
      .addCase(createNewMeeting.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(updateMeetingDetails.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateMeetingDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updateMeeting = action.payload;
      })
      .addCase(updateMeetingDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(getAllMeetings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAllMeetings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allSavedMeetings = action.payload.data;
      })
      .addCase(getAllMeetings.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(getMeetingDetails.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getMeetingDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.meetingDetails = action.payload;
      })
      .addCase(getMeetingDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(getMeetingDetailsForUpdate.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getMeetingDetailsForUpdate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.meetingDetailsForUpdate = action.payload;
      })
      .addCase(getMeetingDetailsForUpdate.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(deleteMeeting.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteMeeting.fulfilled, (state, action) => {
        state.isLoading = false;
        state.deleteMeeting = action.payload;
      })
      .addCase(deleteMeeting.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { setMeetingName, setMeetingDate, resetCreateMeeting, resetQuestions, resetQuestionsStatus, resetMeetingDetail, resetMeetingDelete } = meetingSlice.actions;

export default meetingSlice.reducer;
