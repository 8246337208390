import React, { useEffect, useState } from "react";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCol,
    MDBContainer,
    MDBModal,
    MDBModalBody,
    MDBModalHeader,
    MDBModalFooter,
    MDBRow,
} from "mdb-react-ui-kit";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ConfirmationModal from "../components/common/ConfirmationModal";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { cleanDatabase, resetCleanDatabase } from "../redux/slices/CleanDatabaseSlice";
import Breadcrumb from "../components/common/BreadCrumb";
import { breadcrumbItems } from "../constants/breadcrumb";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { routes } from "../config/routes";

const CleanDataBase = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const data = useSelector((state) => state.cleanDatabase?.data)
    const loading = useSelector((state) => state.cleanDatabase?.loading)
    const error = useSelector((state) => state.cleanDatabase?.error)


    const handleConfirmation = () => {
        Swal.fire({
            title: "Are you sure?",
            text: "You are about to delete the entire database. This action cannot be undone.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#dc4c64",
            cancelButtonColor: "#ccc",
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, cancel",

        }).then((result) => {
            if (result.isConfirmed) {

                dispatch(cleanDatabase())
            }
        });
    }

    useEffect(() => {
        if (data?.status === "Success") {
            Swal.fire({
                icon: "success",
                title: data?.message,
                showConfirmButton: true,
            }).then(() => {
                navigate(routes.dashboard);
            });
        } else if (data?.message) {
            toast.error(data?.message || "Something went wrong");
        }
        dispatch(resetCleanDatabase());
    }, [data]);

    return (
        <div className="main-container w-100">
            <Loader isLoading={loading} />
            <Breadcrumb items={breadcrumbItems.cleanDatabase} />
            <MDBContainer className="d-flex justify-content-center align-items-center row me-auto ms-auto">
                <MDBRow className="w-100 col-10">
                    <MDBCol className="d-flex justify-content-center w-100">
                        <MDBCard className="col-10 p-3">
                            <MDBCardBody>
                                <MDBCardTitle className="mb-3">
                                    <div className="title-container">
                                        <h3>Clean Database</h3>
                                    </div>
                                </MDBCardTitle>
                                <p className="text-muted">
                                    This action will permanently delete all data related to this database. Please review the warnings below before proceeding.
                                </p>
                                <ul className="text-muted">
                                    <li><strong className="text-warning">Irreversibility:</strong> Deleting the database is permanent and cannot be undone. All data related to questions, meetings, participants, and favorites will be lost.</li>
                                    <li><strong className="text-warning">Data Loss:</strong> All records, including uploaded questions, created meetings, participants, and any favorite questions, will be permanently deleted.</li>
                                    <li><strong className="text-warning">User Impact:</strong> All users associated with this database will lose access to their data and interactions related to it.</li>
                                    <li><strong className="text-warning">Backup Requirement:</strong> Ensure you have backed up any important data before proceeding, as deletion cannot be recovered.</li>
                                    <li><strong className="text-warning">Notification to Stakeholders:</strong> Inform all relevant stakeholders and users about the deletion to avoid unexpected disruptions.</li>
                                    <li><strong className="text-warning">Confirmation Step:</strong> This action requires confirmation to prevent accidental deletions. Please review the warnings carefully before confirming.</li>
                                </ul>
                                <MDBBtn className="mt-3" color="danger" onClick={handleConfirmation}>
                                    Delete Database
                                </MDBBtn>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <ToastContainer />

        </div>
    );
};

export default CleanDataBase;
