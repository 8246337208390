import React, { useState, useEffect } from "react";
import { MDBBtn, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter, MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";

const AssignQuestionsModal = ({ open, onClose, questions, handleParticipantData }) => {
  const updatedQuestions = questions.map((question) => ({
    ...question,
    is_favorite: question?.is_favorite ?? "Y",
  }));

  const [participant, setParticipant] = useState({ name: "", jobTitle: "", questions: updatedQuestions });
  const [validationError, setValidationError] = useState({});

  const handleParticipantChange = (field, value) => {
    setParticipant({ ...participant, [field]: value });
    // Clear validation error when user starts typing again
    if (validationError[field]) {
      setValidationError({ ...validationError, [field]: "" });
    }
  };

  const handleSave = () => {
    let errors = {};

    // Validate fields
    if (!participant.name) {
      errors = { ...errors, name: "Participant Name is required." };
    }
    if (!participant.jobTitle) {
      errors = { ...errors, jobTitle: "Job Title is required." };
    }

    if (Object.keys(errors).length > 0) {
      setValidationError(errors);
    } else {
      setValidationError({});

      handleParticipantData(participant);
      setParticipant({ name: "", jobTitle: "", questions: questions });
      setValidationError({});
    }
  };

  return (
    <MDBModal open={open} onClose={onClose} tabIndex="-1">
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Assign Questions to Participant</MDBModalTitle>
            <MDBBtn className="btn-close" color="none" onClick={onClose}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <div className="mb-4 col-12">
              <div className="row g-3 mb-4">
                <div className="col-6">
                  <label className="select-participant-label">Participant Name*:</label>
                  <input type="text" className="form-control" style={{ paddingTop: "6px", paddingBottom: "6px" }} value={participant.name} onChange={(e) => handleParticipantChange("name", e.target.value)} placeholder="Enter Participant Name" />
                  {validationError.name && <small className="text-danger">{validationError.name}</small>}
                </div>
                <div className="col-6">
                  <label className="select-participant-label">Participant Job Title*:</label>
                  <input type="text" className="form-control" style={{ paddingTop: "6px", paddingBottom: "6px" }} value={participant.jobTitle} onChange={(e) => handleParticipantChange("jobTitle", e.target.value)} placeholder="Enter Job Title" />
                  {validationError.jobTitle && <small className="text-danger">{validationError.jobTitle}</small>}
                </div>
              </div>
            </div>
            {/* <h5>All Questions</h5>
            <MDBTable align="middle">
              <MDBTableHead light>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Question</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {questions.map((question, index) => (
                  <tr key={question.id}>
                    <th scope="row">{index + 1}</th>
                    <td>{question.question_text}</td>
                  </tr>
                ))}
              </MDBTableBody>
            </MDBTable> */}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={onClose}>
              Close
            </MDBBtn>
            <MDBBtn onClick={handleSave} color="dark">
              Save changes
            </MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default AssignQuestionsModal;
