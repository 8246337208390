import React, { useEffect, useState } from "react";
import { MDBBtn, MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardImage, MDBInput, MDBIcon } from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import { routes } from "../../config/routes";
import { useDispatch, useSelector } from "react-redux";
import { validateSignUp } from "../../validator/authValidator";
import Loader from "../../components/Loader";
import { resetAuthStates, signup } from "../../redux/slices/authSlice";
import { PuffLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";

function SignUp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.isLoading);
  const user = useSelector((state) => state.auth.registerUser);

  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    password: "",
    repeatPassword: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const ValidationErrors = validateSignUp(formValues);
    if (ValidationErrors === null) {
      setErrors([]);
      const { name, email, password, repeatPassword } = formValues;
      const payload = {
        name: name,
        email: email,
        password: password,
        password_confirmation: repeatPassword,
      };
      dispatch(signup(payload));
    } else {
      setErrors(ValidationErrors);
    }
  };

  useEffect(() => {
    if (user?.status === "Success") {
      setErrors({});
      setFormValues({ name: "", email: "", password: "", repeatPassword: "" });
      toast.success(user?.message);
      dispatch(resetAuthStates());
    } else if (user?.status === "Error") {
      if (user?.message === "validation_error" && Array.isArray(user?.data)) {
        const errorMessages = user?.data.join("<br>"); // Join the array of error messages with <br>
        toast.error(<div dangerouslySetInnerHTML={{ __html: errorMessages }} />);
      } else {
        toast.error(user?.message);
      }
      dispatch(resetAuthStates());
    }
  }, [user, dispatch]);


  return (
    <MDBContainer fluid className="d-flex align-items-center justify-content-center" style={{ minHeight: "100vh", backgroundColor: "#EEEEEE" }}>
      <MDBCard className="text-black m-5 col-6 pt-5 pb-5" style={{ borderRadius: "25px" }}>
        <MDBCardBody>
          <form onSubmit={handleSubmit}>
            <MDBRow>
              <MDBCol md="10" lg="6" className="order-2 order-lg-1 d-flex flex-column align-items-center">
                <h3 className="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4" style={{ fontWeight: "700", fontSize: "2.5rem" }}>
                  Sign up
                </h3>

                <div className="d-flex flex-row align-items-center  " style={{ width: "80%" }}>
                  <MDBIcon fas icon="user me-3" size="lg" />
                  <MDBInput label="Your Name" id="name" type="text" size="lg" className="w-100" value={formValues.name} onChange={handleChange} />
                </div>

                <div className="w-100" style={{ marginLeft: "34%", marginTop: "-2px" }}>
                  {" "}
                  {errors?.name && <small className="text-danger ">{errors?.name}</small>}{" "}
                </div>

                <div className="d-flex flex-row align-items-center mt-4" style={{ width: "80%" }}>
                  <MDBIcon fas icon="envelope me-3" size="lg" />
                  <MDBInput label="Your Email" id="email" type="email" size="lg" autoComplete="off" value={formValues.email} onChange={handleChange} />
                </div>
                <div className="w-100" style={{ marginLeft: "34%", marginTop: "-2px" }}>
                  {errors?.email && <small className="text-danger ">{errors?.email}</small>}{" "}
                </div>

                <div className="d-flex flex-row align-items-center mt-4" style={{ width: "80%" }}>
                  <MDBIcon fas icon="lock me-3" size="lg" />
                  <MDBInput label="Password" id="password" size="lg" type="password" autoComplete="off" value={formValues.password} onChange={handleChange} />
                </div>
                <div className="w-100" style={{ marginLeft: "34%", marginTop: "-2px" }}>
                  {errors?.password && <small className="text-danger ">{errors?.password}</small>}{" "}
                </div>

                <div className="d-flex flex-row align-items-center mt-4" style={{ width: "80%" }}>
                  <MDBIcon fas icon="key me-3" size="lg" />
                  <MDBInput label="Repeat your password" size="lg" id="repeatPassword" type="password" value={formValues.repeatPassword} onChange={handleChange} />
                </div>
                <div className="w-100" style={{ marginLeft: "34%", marginTop: "-2px" }}>
                  {errors?.repeatPassword && <small className="text-danger ">{errors?.repeatPassword}</small>}{" "}
                </div>
                <div className="d-flex flex-row align-items-center mb-4" style={{ width: "80%", paddingLeft: "6%" }}>
                  <MDBBtn className="mb-4 mt-4 ml-5 w-100" size="lg" color="secondary" type="submit" disabled={isLoading}>
                    <span className="d-flex flex-row align-items-center justify-content-center g-p">{isLoading ? <PuffLoader color="orange" className="me-3" size={25} /> : <span>Register</span>}</span>
                  </MDBBtn>
                </div>
              </MDBCol>

              <MDBCol md="10" lg="6" className="order-1 order-lg-2 d-flex align-items-center">
                <MDBCardImage src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-registration/draw1.webp" fluid />
              </MDBCol>
            </MDBRow>
          </form>
          <div className="d-flex flex-row justify-content-center mb-4 curs" role="button">
            <p className="text-center text-muted  mb-0">Have already an account?</p>
            <p onClick={() => navigate(routes.auth.login.path)} className="ms-2 text-decoration-underline fw-bold text-body">
              Login here
            </p>
          </div>
        </MDBCardBody>
      </MDBCard>
      <ToastContainer/>
    </MDBContainer>
  );
}

export default SignUp;
