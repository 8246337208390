import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { MDBCard, MDBCardBody, MDBCardTitle, MDBBtn, MDBBadge, MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import makeAnimated from "react-select/animated";
import { Typewriter } from "react-simple-typewriter";
import { MotionAnimate } from "react-motion-animate";
import Loader from "../components/Loader";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import DataTable from "../components/DataTable";
import { TagsInput } from "react-tag-input-component";
import Breadcrumb from "../components/common/BreadCrumb";
import { breadcrumbItems } from "../constants/breadcrumb";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { createNewMeeting, getAllCategories, getQuestions, resetCreateMeeting, resetQuestions, resetQuestionsStatus } from "../redux/slices/meetingSlice";
import { ToastContainer, toast } from "react-toastify";
import { newMeetingDataValidator, searchValidator } from "../validator/createMeetingValidation";
import { useLocation } from "react-router-dom";

const animatedComponents = makeAnimated();
const limit = 5;
const CreateMeeting = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const fromFavourite = location?.state?.participantData;
  const fromFavouriteSearchCriteria = location?.state?.searchCriteria;
  const [selectKey, setSelectKey] = useState(0);

  const categories = useSelector((state) => state.meeting.categories);
  const questions = useSelector((state) => state.meeting.questions);
  const questionsStatus = useSelector((state) => state.meeting.questionsStatus);

  const createMeeting = useSelector((state) => state.meeting.createNewMeeting);
  const isLoading = useSelector((state) => state.meeting.isLoading);

  const [searchClick, setSearchClick] = useState(false);
  const [submitClick, setSubClick] = useState(false);
  const [participantsfromFavourite, setParticipantsfromFavourite] = useState([]);
  // const [latestSearchCriteria, setLatestSearchCriteria] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [participantData, setParticipantData] = useState([]);
  const [particpantQuestions, setParticipantQuestions] = useState({});
  const [letesSearchingIndex, setLetesSearchingIndex] = useState();
  const [validationError, setValidationError] = useState({});
  const [searchErrors, setSearchErrors] = useState([]);
  const [meeting, setMeeting] = useState({
    companyName: "",
    name: "",
    date: new Date(),
  });

  const newMeetingRef = useRef(null);

  const handleCategoryChange = (participantIndex, category, selectedOption) => {
    const updatedData = [...participantData];
    updatedData[participantIndex] = { ...updatedData[participantIndex], [category]: selectedOption };
    setParticipantData(updatedData);

    if (letesSearchingIndex === participantIndex) {
      const error = searchValidator(updatedData[participantIndex], categories, participantIndex);
      setSearchErrors(error);
    }
  };

  const handleMeetingNameChange = (e) => {
    const newName = e.target.value;
    setMeeting((prevMeeting) => ({
      ...prevMeeting,
      name: newName,
    }));

    setValidationError((prevErrors) => ({
      ...prevErrors,
      meeting_name: newName ? "" : "Meeting name is required", // Assuming the error object structure
    }));
  };

  const handleDateChange = (date) => {
    setMeeting({ ...meeting, date: date });

    setValidationError((prevErrors) => ({
      ...prevErrors,
      meeting_date_time: date ? "" : "Meeting date time is required", // Assuming the error object structure
    }));
  };

  const handleCompanyNameChange = (e) => {
    setMeeting({ ...meeting, companyName: e.target.value });

    setValidationError((prevErrors) => ({
      ...prevErrors,
      company_name: e.target.value ? "" : "Company name is required", // Assuming the error object structure
    }));
  };

  const handleChange = (newTags) => {
    const filteredTags = newTags.filter((tag) => tag.length <= 50);

    // Remove the participant and their data if they are removed from the list
    const removedParticipants = participants.filter((participant) => !filteredTags.includes(participant));
    const removedIndices = participants.map((participant, index) => (removedParticipants.includes(participant) ? index : -1)).filter((index) => index !== -1);
    const updatedParticipantData = participantData.filter((_, index) => !removedParticipants.includes(participants[index]));

    // Update the state for participants and participantData
    const participant_name = filteredTags?.slice(0, 3);
    setParticipants(participant_name);
    setParticipantData(updatedParticipantData);

    // Remove the questions for the removed participants
    const updatedParticipantQuestions = { ...particpantQuestions };
    removedIndices.forEach((index) => {
      delete updatedParticipantQuestions[index];
    });
    setParticipantQuestions(updatedParticipantQuestions);
    setSelectKey((prevKey) => prevKey + 1);
    if (submitClick) {
      setValidationError((prevErrors) => ({
        ...prevErrors,
        participants: filteredTags?.length > 0 ? "" : "At least one participant is required.", // Assuming the error object structure
      }));
    }
  };

  const handleSubmit = () => {
    const formattedDate = moment(meeting?.date).format("YYYY-MM-DD HH:mm:ss");
    setSubClick(true);
    const payload = {
      meeting_date_time: formattedDate,
      meeting_name: meeting?.name,
      company_name: meeting?.companyName,
      participants:
        participants?.map((participantName, index) => {
          const participant = participantData?.[index];
          const searchCriteria = Object.values(participant ?? {})
            .filter((value) => value && value.keyword_id && value.category_id)
            .map((value) => ({
              keyword_id: value.keyword_id,
              category_id: value.category_id,
            }));
          const questionIds = participant?.questions?.map((question) => question.question_id) ?? [];
          return {
            participants_name: participantName,
            job_title: participant?.jobTitle ?? "",
            search_criteria: searchCriteria,
            question_ids: questionIds,
          };
        }) ?? [],
    };
    const errors = newMeetingDataValidator(payload, categories);

    if (errors === null) {
      setValidationError({});
      dispatch(createNewMeeting(payload));
    } else {
      setValidationError(errors);
      if (newMeetingRef.current) {
        newMeetingRef.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
  };

  const handleSearch = (participantIndex, limitSkiipedQuestions, skippedQuestionsArray = [], isRemove) => {
    setLetesSearchingIndex(participantIndex);
    setSearchClick(true);

    if (participantIndex !== null) {
      // Clear questions for the specified participant index if isRemove is true
      if (isRemove) {
        const updatedParticipantData = [...participantData];
        const updatedParticipantQuestions = { ...particpantQuestions };

        if (updatedParticipantData[participantIndex]) {
          updatedParticipantData[participantIndex].questions = [];
        }

        if (updatedParticipantQuestions[participantIndex]) {
          delete updatedParticipantQuestions[participantIndex];
        }

        setParticipantData(updatedParticipantData);
        setParticipantQuestions(updatedParticipantQuestions);
      }

      // Validate participant data
      const error = searchValidator(participantData[participantIndex], categories, participantIndex);
      if (error === null) {
        setSearchErrors([]);
        const selectedKeywords = Object.values(participantData[participantIndex]).filter((option) => {
          return option !== undefined && typeof option !== "string" && !(typeof option === "object" && Object.keys(option).length === 0) && option.keyword_id !== undefined && option.category_id !== undefined;
        });

        const payload = selectedKeywords.map((option) => ({
          keyword_id: option.keyword_id,
          category_id: option.category_id,
        }));

        setParticipantData((prevData) => {
          const updatedData = [...prevData];
          updatedData[participantIndex] = { ...updatedData[participantIndex], ["latestSearchCriteria"]: payload };
          return updatedData;
        });
        const formData = new FormData();
        formData.append("search", JSON.stringify(payload));
        formData.append("limit", limitSkiipedQuestions ?? limit);
        skippedQuestionsArray.forEach((question, index) => {
          formData.append(`skipped_questions[${index}]`, question);
        });

        dispatch(getQuestions(formData));
      } else {
        setSearchErrors(error);
      }
    }
  };

  const handleQuestionsChange = (questions, index) => {
    const updatedData = [...participantData];
    updatedData[index] = { ...updatedData[index], questions: questions };
    setParticipantData(updatedData);
  };

  const handleRefresh = () => {
    setParticipantData([]);
    dispatch(resetQuestions());
    setParticipants([]);
    setSubClick(false);
    setMeeting({
      companyName: "",
      name: "",
      date: new Date(),
    });
    setParticipantQuestions({});
    setValidationError({});
  };
  const filterCategoryByName = (data, categoryName) => {
    const category = data?.find((category) => category.category_name.toLowerCase() === categoryName.toLowerCase());
    if (category) {
      return category.keywords.map((keyword) => ({
        value: keyword.keyword_value,
        label: keyword.keyword_value,
        keyword_id: keyword?.keyword_id,
        category_id: category?.category_id,
      }));
    }
    return [];
  };

  const handleRefreshSelectedQuestions = (index, limit, selectedQuestions) => {
    handleSearch(index, limit, selectedQuestions);
  };

  useEffect(() => {
    dispatch(getAllCategories());
  }, []);

  useEffect(() => {
    if (questions?.length > 0) {
      setParticipantQuestions({ ...particpantQuestions, [letesSearchingIndex]: questions });
      dispatch(resetQuestions());
    }

    if (questionsStatus) {
      toast.info("No results found for the selected category and keywords");
      dispatch(resetQuestionsStatus());
    }
  }, [questions, questionsStatus]);

  useEffect(() => {
    if (createMeeting?.status === "Success") {
      toast.success("Meeting created successfully");
      handleRefresh();
    } else if (createMeeting?.status === "Error") {
      if (createMeeting?.message === "validation_error" && Array.isArray(createMeeting?.data)) {
        const errorMessages = createMeeting?.data.join("<br>"); // Join the array of error messages with <br>
        toast.error(<div dangerouslySetInnerHTML={{ __html: errorMessages }} />);
      } else {
        toast.error(createMeeting?.message);
      }
      // toast.error(createMeeting?.message || "Somthing went wrong");
    }
    dispatch(resetCreateMeeting());
  }, [createMeeting]);

  useEffect(() => {
    if (fromFavourite) {
      const participantsName = fromFavourite?.map((participant) => participant?.name);

      const participantsJobTitle = fromFavourite?.map((participant) => participant?.jobTitle);

      const participantsQuestions = fromFavourite?.map((participant) => participant?.questions);
      setParticipantQuestions(participantsQuestions);
      setParticipants(participantsName);

      participantsJobTitle?.map((jobTitle, index) => {
        setParticipantData((prevData) => {
          const updatedData = [...prevData];
          updatedData[index] = { ...updatedData[index], jobTitle: jobTitle };
          return updatedData;
        });
      });

      participantsQuestions?.map((questions, index) => {
        setParticipantData((prevData) => {
          const updatedData = [...prevData];
          updatedData[index] = { ...updatedData[index], questions: questions };
          return updatedData;
        });
      });

      participantsName?.map((part, i) => {
        fromFavouriteSearchCriteria?.map((item, index) => {
          setParticipantData((prevData) => {
            const updatedData = [...prevData];
            updatedData[i] = { ...updatedData[i], [item?.value]: item };
            return updatedData;
          });
        });
      });
    }
  }, [fromFavourite]);

  function capitalizeFirstLetter(str) {
    return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  }

  return (
    <div className="main-container w-100">
      <Loader isLoading={isLoading} />
      <Breadcrumb items={breadcrumbItems.createNewMeetings} />
      <MDBContainer className="d-flex justify-content-center align-items-center row me-auto ms-auto" ref={newMeetingRef}>
        <MotionAnimate animation="fadeInUp" reset={false} distance={50} delay={0.4} speed={0.5}>
          <MDBRow className="w-100 col-12">
            <MDBCol className="d-flex justify-content-center w-100">
              <MDBCard className="w-100 p-3">
                <MDBCardBody>
                  <MDBCardTitle className="mb-3">
                    <Typewriter words={["Create New Meeting"]} />
                  </MDBCardTitle>
                  <div className="row">
                    <div className="mb-4 col-4">
                      <label className="select-participant-label">Company Name:</label>
                      <input type="text" className="form-control py-2" placeholder="Enter Company Name" value={meeting.companyName} onChange={handleCompanyNameChange} />
                      {validationError?.company_name && <small className="text-danger">{validationError?.company_name}</small>}
                    </div>
                    <div className="mb-4 col-4">
                      <label className="select-participant-label">Meeting Name:</label>
                      <input type="text" className="form-control py-2" placeholder="Enter Meeting Name" value={meeting.name} onChange={handleMeetingNameChange} />
                      {validationError?.meeting_name && <small className="text-danger">{validationError?.meeting_name}</small>}
                    </div>
                    <div className="col-4">
                      <div className="select-participant-label">Select Date And Time:</div>
                      <DateTimePicker onChange={handleDateChange} value={meeting.date} disableClock={true} isClockOpen={false} />
                      {validationError?.meeting_date_time && <small className="text-danger">{validationError?.meeting_date_time}</small>}
                    </div>
                    <div className="mb-4 col-12">
                      <label className="select-participant-label">Add Participants (Maximum 3):</label>
                      <TagsInput isEditOnRemove={true} placeHolder="Add Participant" maxTags={3} value={participants} onChange={handleChange} />
                      {participants.some((tag) => tag.length > 50) && <small className="text-danger">Participant name should not be longer than 50 characters.</small>}
                      {validationError?.participants && <small className="text-danger">{validationError?.participants}</small>}
                    </div>
                  </div>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MotionAnimate>
        {participants?.length > 0 &&
          participants?.map((participant, i) => {
            const participantErrors = searchErrors?.filter((error) => error.index === i) || [];

            const getErrorMessage = (categoryId) => {
              const error = participantErrors.find((err) => err.category_id === categoryId);
              return error ? error.message : null;
            };
            return (
              <MotionAnimate key={i} animation="fadeInUp" reset={false} distance={50} delay={0.4} speed={0.5}>
                <MDBRow className="w-100 mt-5 col-12 mb-5">
                  <MDBCol>
                    <MDBCard className="w-100 p-3">
                      <MDBCardBody>
                        <MDBCardTitle className="mb-3">
                          Select dropdowns to search category-specific questions for
                          <MDBBadge className="mx-2" color="success" light>
                            {participant}
                          </MDBBadge>
                        </MDBCardTitle>

                        <div className="row  g-3">
                          <div className="col-4">
                            <label className="select-participant-label">Participant Job Title:</label>
                            <input type="text" className="form-control" key={selectKey} style={{ paddingTop: "6px", paddingBottom: "6px" }} name={`jobTitle`} value={participantData[i]?.jobTitle} onChange={(e) => handleCategoryChange(i, "jobTitle", e.target.value)} placeholder="Enter Job Title" />
                            {getErrorMessage("jobTitle") && <small className="text-danger">{getErrorMessage("jobTitle")}</small>}
                          </div>

                          {categories?.map((category, index) => {
                            const options = category.keywords.map((keyword) => ({
                              value: keyword.keyword_value,
                              label: keyword.keyword_value,
                              keyword_id: keyword?.keyword_id,
                              category_id: category?.category_id,
                            }));

                            let defaultValue;
                            if (fromFavouriteSearchCriteria && participantData[i]) {
                              defaultValue = options.find((option) => fromFavouriteSearchCriteria?.some((criteria) => criteria.keyword_id === option.keyword_id && criteria.category_id === option.category_id));
                            }

                            return (
                              <div className="col-4" key={index}>
                                <label className="select-participant-label">
                                  {capitalizeFirstLetter("Select")} {capitalizeFirstLetter(category?.category_name)}
                                  {category?.is_mandatory === "Y" ? "*" : ""}:
                                </label>
                                <Select
                                  key={selectKey}
                                  className="basic-single"
                                  components={animatedComponents}
                                  classNamePrefix="select"
                                  isDisabled={false}
                                  isClearable={true}
                                  isRtl={false}
                                  defaultValue={defaultValue}
                                  isSearchable={true}
                                  isLoading={isLoading}
                                  name={category?.category_name}
                                  options={options}
                                  value={participantData[i]?.[category?.category_name]}
                                  onChange={(selectedOption) => handleCategoryChange(i, category?.category_name, selectedOption)}
                                />
                                {getErrorMessage(category?.category_id) && <small className="text-danger">{getErrorMessage(category?.category_id)}</small>}
                              </div>
                            );
                          })}
                        </div>

                        <MotionAnimate animation="fadeInUp" reset={false} distance={50} delay={0.4} speed={0.5}>
                          <MDBRow className="w-100 col-12 m-0 p-0">
                            <MDBCol className="d-flex mt-3 w-100 p-0">
                              <MDBBtn className="mt-3 mb-3 me-0 ms-0" disabled={!participantData[i]} color="dark" onClick={() => handleSearch(i, limit, [], true)}>
                                Search
                              </MDBBtn>
                            </MDBCol>
                          </MDBRow>
                        </MotionAnimate>

                        {(particpantQuestions[i]?.length === 0 || particpantQuestions[i] === undefined) && validationError[i] && <small className="text-danger">{validationError[i]}</small>}

                        {particpantQuestions[i]?.length > 0 ? (
                          <MotionAnimate key={i} animation="fadeInUp" reset={false} distance={50} delay={0.4} speed={0.5}>
                            <MDBCol className="d-flex w-100">
                              <DataTable data={particpantQuestions[i]} name={participant?.label} participants={participantData} index={i} getSelectedQuestions={handleQuestionsChange} handleRefreshSelectedQuestions={handleRefreshSelectedQuestions} status={createMeeting?.status === "Success"} />
                            </MDBCol>
                          </MotionAnimate>
                        ) : (
                          ""
                        )}
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MotionAnimate>
            );
          })}
        <>
          <MDBRow className="w-100 col-12 m-0">
            <MDBCol className="d-flex w-100"></MDBCol>
          </MDBRow>
          <MotionAnimate animation="fadeInUp" reset={false} distance={50} delay={0.4} speed={0.5}>
            <MDBRow className="w-100 col-12 m-0">
              <MDBCol className="d-flex w-100">
                <MDBBtn className="mt-5 mb-5" color="danger" onClick={handleRefresh}>
                  Cancel
                </MDBBtn>
                <MDBBtn className="mt-5 mb-5 ms-2" color="dark" onClick={handleSubmit}>
                  Save
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </MotionAnimate>
        </>
      </MDBContainer>
      <ToastContainer />
    </div>
  );
};

export default CreateMeeting;
